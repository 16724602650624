/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function HourglassSimpleMedium(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M139.3,128l34.4-34.3h0L208,59.3A16,16,0,0,0,196.7,32H59.3A16,16,0,0,0,48,59.3L116.7,128,48,196.7A16,16,0,0,0,59.3,224H196.7A16,16,0,0,0,208,196.7Zm57.4-80-24,24H83.3l-24-24ZM59.3,208,120,147.3V168a8,8,0,0,0,16,0V147.3L196.7,208Z" />
    </Svg>
  );
}

export default HourglassSimpleMedium;
