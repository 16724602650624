/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Circle, Path, Line } from 'react-native-svg';

import { IconProps } from '../lib';

function Mountains(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Circle cx={164} cy={52} r={20} opacity={0.2} fill={props.color} />
      <Path
        d="M144.1,159.4l33-55.8a8.1,8.1,0,0,1,13.8,0L248,200H168Z"
        opacity={0.2}
      />
      <Path d="M50.4,128h75.2L94.9,75.7a8.1,8.1,0,0,0-13.8,0Z" opacity={0.2} />
      <Circle
        cx={164}
        cy={52}
        r={20}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M8,200,81.1,75.7a8.1,8.1,0,0,1,13.8,0L168,200Z"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={50.4}
        y1={128}
        x2={125.6}
        y2={128}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M144.1,159.4l33-55.8a8.1,8.1,0,0,1,13.8,0L248,200H168"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default Mountains;
