/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Option(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M232,184a8,8,0,0,1-8,8H161a16.2,16.2,0,0,1-14.4-8.8L95.1,80H32a8,8,0,0,1,0-16H95.1a16,16,0,0,1,14.3,8.8L161,176h63A8,8,0,0,1,232,184ZM152,80h72a8,8,0,0,0,0-16H152a8,8,0,0,0,0,16Z" />
    </Svg>
  );
}

export default Option;
