/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Polygon, Line, Polyline } from 'react-native-svg';

import { IconProps } from '../lib';

function LightningSlash(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Polygon
        points="96 240 112 160 48 136 160 16 144 96 208 120 96 240"
        opacity={0.2}
      />
      <Line
        x1={48}
        y1={40}
        x2={208}
        y2={216}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Polyline
        points="163.8 167.4 96 240 112 160 48 136 92.2 88.6"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Polyline
        points="114.1 65.1 160 16 144 96 208 120 185.7 143.9"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default LightningSlash;
