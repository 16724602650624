/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Ticket(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M227.2,104.5A16.1,16.1,0,0,0,240,88.8V64a16,16,0,0,0-16-16H32A16,16,0,0,0,16,64V88.8a16.1,16.1,0,0,0,12.8,15.7,24,24,0,0,1,0,47A16.1,16.1,0,0,0,16,167.2V192a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V167.2a16.1,16.1,0,0,0-12.8-15.7,24,24,0,0,1,0-47ZM32,167.2a40,40,0,0,0,0-78.4V64H88V192H32Z" />
    </Svg>
  );
}

export default Ticket;
