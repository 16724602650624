/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function CaretCircleDoubleUp(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M201.5,201.5a104,104,0,1,0-147,0A103.9,103.9,0,0,0,201.5,201.5Zm-99.8-83.8a8.1,8.1,0,0,1-11.4,0,8.2,8.2,0,0,1,0-11.4l32-32a8.1,8.1,0,0,1,11.4,0l32,32a8.1,8.1,0,0,1-11.4,11.4L128,91.3Zm0,56a8.1,8.1,0,0,1-11.4,0,8.2,8.2,0,0,1,0-11.4l32-32a8.1,8.1,0,0,1,11.4,0l32,32a8.1,8.1,0,0,1-11.4,11.4L128,147.3Z" />
    </Svg>
  );
}

export default CaretCircleDoubleUp;
