/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Wrench(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M210.9,146.9A72.6,72.6,0,0,1,131,162L79,222.2c-.1.2-.3.3-.4.4a31.9,31.9,0,0,1-45.2,0,31.9,31.9,0,0,1,0-45.2l.4-.4L94,125a72,72,0,0,1,94.1-95.2,7.9,7.9,0,0,1,4.7,5.8,8,8,0,0,1-2.2,7.2L151.7,81.7l3.7,18.9,18.9,3.7,38.9-38.9a8,8,0,0,1,7.2-2.2,7.9,7.9,0,0,1,5.8,4.7A71.7,71.7,0,0,1,210.9,146.9Z" />
    </Svg>
  );
}

export default Wrench;
