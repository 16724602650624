/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function ArrowCircleDownLeft(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm33.7,81.7L119.3,148H148a8,8,0,0,1,0,16H100a8.5,8.5,0,0,1-3.1-.6,8.2,8.2,0,0,1-4.3-4.3A8.5,8.5,0,0,1,92,156V108a8,8,0,0,1,16,0v28.7l42.3-42.4a8.1,8.1,0,0,1,11.4,11.4Z" />
    </Svg>
  );
}

export default ArrowCircleDownLeft;
