/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function GenderMale(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M216,32H168a8,8,0,0,0,0,16h28.7L154.6,90.1a80,80,0,1,0,11.3,11.3L208,59.3V88a8,8,0,0,0,16,0V40A8,8,0,0,0,216,32ZM149.3,197.3a64,64,0,1,1,0-90.6A64.3,64.3,0,0,1,149.3,197.3Z" />
    </Svg>
  );
}

export default GenderMale;
