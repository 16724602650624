/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function SlackLogo(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M221.1,128A32,32,0,0,0,200,72a32.2,32.2,0,0,0-16,4.3V56a32,32,0,0,0-56-21.1A32,32,0,0,0,72,56a32.2,32.2,0,0,0,4.3,16H56a32,32,0,0,0-21.1,56A32,32,0,0,0,56,184a32.2,32.2,0,0,0,16-4.3V200a32,32,0,0,0,56,21.1A32,32,0,0,0,184,200a32.2,32.2,0,0,0-4.3-16H200a32,32,0,0,0,21.1-56ZM88,56a16,16,0,0,1,32,0V72H104A16,16,0,0,1,88,56ZM40,104A16,16,0,0,1,56,88h48a16,16,0,0,1,16,16v16H56A16,16,0,0,1,40,104Zm128,96a16,16,0,0,1-32,0V184h16A16,16,0,0,1,168,200Zm32-32H152a16,16,0,0,1-16-16V136h64a16,16,0,0,1,0,32Z" />
    </Svg>
  );
}

export default SlackLogo;
