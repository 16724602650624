/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Keyhole(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm18.4-81.8,12.5,29.1a12,12,0,0,1-11,16.7H108.1a12,12,0,0,1-11-16.7l12.5-29.1a32,32,0,0,1-2.9-50.1,32.4,32.4,0,0,1,25-7.9,32,32,0,0,1,14.7,58Z" />
    </Svg>
  );
}

export default Keyhole;
