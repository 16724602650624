/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function BugBeetle(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M50.4,33.8a8,8,0,0,1,11-11.6L80.1,40a79.8,79.8,0,0,1,95.7-.1l18.6-17.7a8,8,0,0,1,11.3.3,7.9,7.9,0,0,1-.3,11.3L187.6,50.7a79.6,79.6,0,0,1,20,45.3H48.4A79.9,79.9,0,0,1,68.3,50.8ZM32,96a8,8,0,0,0,0,16H48V96Zm176,56a70.3,70.3,0,0,1-.4,8H224a8,8,0,0,1,0,16H204.3a80,80,0,0,1-152.6,0H32a8,8,0,0,1,0-16H48.4a70.3,70.3,0,0,1-.4-8v-8H32a8,8,0,0,1,0-16H48V112H208v16h16a8,8,0,0,1,0,16H208Zm-72-16a8,8,0,0,0-16,0v64a8,8,0,0,0,16,0Zm96-32a8,8,0,0,0-8-8H208v16h16A8,8,0,0,0,232,104Z" />
    </Svg>
  );
}

export default BugBeetle;
