/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function NumberCircleSix(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M148,144a20,20,0,1,1-20-20A20.1,20.1,0,0,1,148,144Zm84-16A104,104,0,1,1,128,24,104.2,104.2,0,0,1,232,128Zm-68,16a36,36,0,0,0-36-36h-1.8l16.7-27.9a8,8,0,1,0-13.8-8.2l-32.2,54-.3.6A36,36,0,1,0,164,144Z" />
    </Svg>
  );
}

export default NumberCircleSix;
