/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function TShirt(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M245.4,61.3,196,33.1h0a8,8,0,0,0-4-1.1H160a8,8,0,0,0-8,8,24,24,0,0,1-48,0,8,8,0,0,0-8-8H64a8,8,0,0,0-4,1.1h0L10.6,61.3a15.9,15.9,0,0,0-6.4,21l18.4,36.9A16,16,0,0,0,36.9,128H56v80a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V128h19.1a16,16,0,0,0,14.3-8.8l18.4-36.9A15.9,15.9,0,0,0,245.4,61.3ZM36.9,112,18.5,75.2,56,53.8V112Zm182.2,0H200V53.8l37.5,21.4Z" />
    </Svg>
  );
}

export default TShirt;
