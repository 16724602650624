/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Nut(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M219.9,66.7l-84-47.4a15.9,15.9,0,0,0-15.8,0l-84,47.4a16.2,16.2,0,0,0-8.1,14v94.6a16.2,16.2,0,0,0,8.1,14l84,47.4a15.9,15.9,0,0,0,15.8,0l84-47.4a16.2,16.2,0,0,0,8.1-14V80.7A16.2,16.2,0,0,0,219.9,66.7ZM128,164a36,36,0,1,1,36-36A36,36,0,0,1,128,164Z" />
    </Svg>
  );
}

export default Nut;
