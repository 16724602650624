/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function BracketsAngle(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M86.7,44.3,33.5,128l53.2,83.7a7.9,7.9,0,0,1-2.4,11A7.5,7.5,0,0,1,80,224a7.9,7.9,0,0,1-6.7-3.7l-56-88a7.9,7.9,0,0,1,0-8.6l56-88a8,8,0,1,1,13.4,8.6Zm152,79.4-56-88a8,8,0,1,0-13.4,8.6L222.5,128l-53.2,83.7a7.9,7.9,0,0,0,2.4,11A7.5,7.5,0,0,0,176,224a7.9,7.9,0,0,0,6.7-3.7l56-88A7.9,7.9,0,0,0,238.7,123.7Z" />
    </Svg>
  );
}

export default BracketsAngle;
