/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Line, Polyline, Polygon } from 'react-native-svg';

import { IconProps } from '../lib';

function CurrencyEth(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Line
        x1={128}
        y1={16}
        x2={128}
        y2={240}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={12}
      />
      <Polyline
        points="216 128 128 168 40 128"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={12}
      />
      <Polygon
        points="128 16 216 128 128 240 40 128 128 16"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={12}
      />
    </Svg>
  );
}

export default CurrencyEth;
