/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path, Circle, Polyline } from 'react-native-svg';

import { IconProps } from '../lib';

function LinuxLogo(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path
        d="M32,216S64,160,64,96a64,64,0,0,1,128,0c0,64,32,120,32,120"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
      />
      <Circle cx={100} cy={108} r={8} fill={props.color} />
      <Circle cx={156} cy={108} r={8} fill={props.color} />
      <Polyline
        points="160 144 128 160 96 144"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
      />
      <Path
        d="M86.4,216a48.1,48.1,0,0,1,83.2,0"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
      />
    </Svg>
  );
}

export default LinuxLogo;
