/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function HandbagSimple(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M239,206.2l-14.3-128A15.9,15.9,0,0,0,208.8,64H175.3a48,48,0,0,0-94.6,0H47.2A15.9,15.9,0,0,0,31.3,78.2L17,206.2a16.2,16.2,0,0,0,4,12.5A15.9,15.9,0,0,0,32.9,224H223.1a15.9,15.9,0,0,0,11.9-5.3A16.2,16.2,0,0,0,239,206.2ZM128,40a32.1,32.1,0,0,1,31,24H97A32.1,32.1,0,0,1,128,40Z" />
    </Svg>
  );
}

export default HandbagSimple;
