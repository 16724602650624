/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path, Line } from 'react-native-svg';

import { IconProps } from '../lib';

function Wine(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path
        d="M200.4,106.7l1.7,5.6a24.1,24.1,0,0,1-1.2,16.7,80,80,0,0,1-145.8,0,24.1,24.1,0,0,1-1.2-16.7l3.6-12h0C68.1,94.6,92.7,86.4,128,104c37.6,18.8,63.1,8.2,72.4,2.7Z"
        opacity={0.2}
      />
      <Line
        x1={128}
        y1={176}
        x2={128}
        y2={232}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={88}
        y1={232}
        x2={168}
        y2={232}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M80,24h96l26.1,88.3a24.1,24.1,0,0,1-1.2,16.7,80,80,0,0,1-145.8,0,24.1,24.1,0,0,1-1.2-16.7Z"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M57.5,100.3C68.1,94.6,92.7,86.4,128,104c37.6,18.8,63.1,8.2,72.4,2.7"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default Wine;
