/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Headset(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M233.5,128v80a40.1,40.1,0,0,1-40,40H136a8,8,0,0,1,0-16h57.5a24.1,24.1,0,0,0,24-24v-1.4a23.6,23.6,0,0,1-8,1.4h-16a24,24,0,0,1-24-24V144a24,24,0,0,1,24-24h23.6a88,88,0,0,0-88.3-80h-.1a87.8,87.8,0,0,0-88.3,80H64a24.1,24.1,0,0,1,24,24v40a24.1,24.1,0,0,1-24,24H48a24.1,24.1,0,0,1-24-24V128A104,104,0,0,1,128.7,24h.8a104.1,104.1,0,0,1,104,104Z" />
    </Svg>
  );
}

export default Headset;
