/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function ArrowDownRight(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M200,88V192a8,8,0,0,1-8,8H88a8,8,0,0,1-7.4-4.9,8.4,8.4,0,0,1,1.7-8.8L128.7,140,58.3,69.7A8.1,8.1,0,0,1,69.7,58.3L140,128.7l46.3-46.4a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,200,88Z" />
    </Svg>
  );
}

export default ArrowDownRight;
