/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function ThermometerSimple(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M168,143.3V48a40,40,0,0,0-80,0v95.3A59.4,59.4,0,0,0,68,188a60,60,0,0,0,120,0A59.4,59.4,0,0,0,168,143.3ZM128,24a24.1,24.1,0,0,1,24,24V80H104V48A24.1,24.1,0,0,1,128,24Z" />
    </Svg>
  );
}

export default ThermometerSimple;
