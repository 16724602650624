/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function CellSignalHigh(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M198.1,28.5A15.9,15.9,0,0,0,180.7,32L16,196.7a15.7,15.7,0,0,0-4.7,11.6,4.9,4.9,0,0,0,.1,1.2,14.7,14.7,0,0,0,1.1,4.6,16,16,0,0,0,6.9,7.8l1,.6,2.2.8a14.9,14.9,0,0,0,4.7.7H192a16,16,0,0,0,16-16V43.3A16,16,0,0,0,198.1,28.5ZM192,208H168V67.3l24-24Z" />
    </Svg>
  );
}

export default CellSignalHigh;
