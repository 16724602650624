/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function ArrowLineRight(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M189.7,122.3a8.1,8.1,0,0,1,0,11.4l-72,72A8.3,8.3,0,0,1,112,208a8.5,8.5,0,0,1-3.1-.6A8,8,0,0,1,104,200V136H32a8,8,0,0,1,0-16h72V56a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7ZM216,32a8,8,0,0,0-8,8V216a8,8,0,0,0,16,0V40A8,8,0,0,0,216,32Z" />
    </Svg>
  );
}

export default ArrowLineRight;
