/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function FolderSimpleStar(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M40,64V200h80a8,8,0,0,1,0,16H40a16,16,0,0,1-16-16V64A16,16,0,0,1,40,48H93.3a15.6,15.6,0,0,1,9.6,3.2L130.7,72H216a16,16,0,0,1,16,16v32a8,8,0,0,1-16,0V88H130.7a15.6,15.6,0,0,1-9.6-3.2L93.3,64Zm203.6,95.2a8.1,8.1,0,0,0-7-5.6l-29.8-2.3-11.5-26.5a8,8,0,0,0-14.6,0l-11.5,26.5-29.8,2.3a8.1,8.1,0,0,0-4.5,14.2l22.5,18.6-6.8,27.7a7.9,7.9,0,0,0,3,8.3,8,8,0,0,0,8.8.5L188,207.8l25.6,15.1a8.7,8.7,0,0,0,4.1,1.1,7.4,7.4,0,0,0,4.7-1.6,7.9,7.9,0,0,0,3-8.3l-6.8-27.7,22.5-18.6A8,8,0,0,0,243.6,159.2Z" />
    </Svg>
  );
}

export default FolderSimpleStar;
