/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function NumberFive(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M165.2,122.7a64,64,0,0,1,0,90.6,64.5,64.5,0,0,1-90.8,0,8,8,0,1,1,11.2-11.4,48.4,48.4,0,0,0,68.3,0A48,48,0,0,0,119.8,120a48.3,48.3,0,0,0-34.2,14.1,8,8,0,0,1-13.5-7L87.8,30.7A7.9,7.9,0,0,1,95.7,24H176a8,8,0,0,1,0,16H102.5L91,110.8a63,63,0,0,1,28.8-6.8A64.4,64.4,0,0,1,165.2,122.7Z" />
    </Svg>
  );
}

export default NumberFive;
