/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function ThumbsUp(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M231.9,80.1a24.4,24.4,0,0,0-18-8.1H160V56a40.1,40.1,0,0,0-40-40,8.2,8.2,0,0,0-7.2,4.4L75,96H32a16,16,0,0,0-16,16v88a16,16,0,0,0,16,16H201.9a24.1,24.1,0,0,0,23.8-21l12-96A24.5,24.5,0,0,0,231.9,80.1ZM32,112H72v88H32Z" />
    </Svg>
  );
}

export default ThumbsUp;
