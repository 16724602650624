/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function WifiSlash(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M53.9,34.6A8,8,0,0,0,42,45.4l4.7,5.1A182.8,182.8,0,0,0,15.3,69.6,15.6,15.6,0,0,0,9.1,80.5a16.3,16.3,0,0,0,3.8,12.3L115.8,214a15.9,15.9,0,0,0,24.3.1l26.7-31.5,35.3,38.8A8.2,8.2,0,0,0,208,224a7.8,7.8,0,0,0,5.4-2.1,8,8,0,0,0,.6-11.3Z" />
      <Path d="M246.9,80.5a15.6,15.6,0,0,0-6.2-10.9A188,188,0,0,0,92.6,35.3a8.3,8.3,0,0,0-6.1,5.4,8.2,8.2,0,0,0,1.7,7.9l93.4,102.7a8.3,8.3,0,0,0,5.9,2.6h.2a8.3,8.3,0,0,0,5.9-2.8l49.6-58.4A16.4,16.4,0,0,0,246.9,80.5Z" />
    </Svg>
  );
}

export default WifiSlash;
