/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path, Ellipse } from 'react-native-svg';

import { IconProps } from '../lib';

function Vignette(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path
        d="M216,48H40a8,8,0,0,0-8,8V200a8,8,0,0,0,8,8H216a8,8,0,0,0,8-8V56A8,8,0,0,0,216,48ZM128,176c-35.3,0-64-21.5-64-48s28.7-48,64-48,64,21.5,64,48S163.3,176,128,176Z"
        opacity={0.2}
      />
      <Rect
        x={32}
        y={48}
        width={192}
        height={160}
        rx={8}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Ellipse
        cx={128}
        cy={128}
        rx={64}
        ry={48}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default Vignette;
