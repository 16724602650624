/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path, Circle, Line } from 'react-native-svg';

import { IconProps } from '../lib';

function UserCircleMinus(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path
        d="M128,32A96,96,0,0,0,63.8,199.4h0A72,72,0,0,1,128,160a40,40,0,1,1,40-40,40,40,0,0,1-40,40,72,72,0,0,1,64.2,39.4A96,96,0,0,0,128,32Z"
        opacity={0.2}
      />
      <Circle
        cx={128}
        cy={120}
        r={40}
        fill="none"
        stroke={props.color}
        strokeMiterlimit={10}
        strokeWidth={16}
      />
      <Path
        d="M63.8,199.4a72,72,0,0,1,128.4,0"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={176}
        y1={56}
        x2={224}
        y2={56}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M218.5,96a94.4,94.4,0,0,1,5.5,32,96,96,0,1,1-96-96,93.3,93.3,0,0,1,15.1,1.2"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default UserCircleMinus;
