/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Placeholder(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM187.3,187.3a7.7,7.7,0,0,1-5.6,2.4,8,8,0,0,1-5.7-2.4L68.7,80A8,8,0,1,1,80,68.7L187.3,176A8,8,0,0,1,187.3,187.3Z" />
    </Svg>
  );
}

export default Placeholder;
