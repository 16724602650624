/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Baseball(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M51.9,63.9a4,4,0,0,0-6,.3,103.9,103.9,0,0,0,0,127.6,4,4,0,0,0,6,.3l7.3-7.3a8,8,0,0,1,6.2-2.8,7.9,7.9,0,0,1,5.2,2,8.2,8.2,0,0,1,.6,11.4,99.9,99.9,0,0,1-7.8,8.2,3.9,3.9,0,0,0,.2,6,103.8,103.8,0,0,0,128.8,0,3.9,3.9,0,0,0,.2-6,103.3,103.3,0,0,1-7.9-8.3,8,8,0,1,1,12.1-10.5l7.3,7.3a4,4,0,0,0,6-.3,103.9,103.9,0,0,0,0-127.6,4,4,0,0,0-6-.3l-7.3,7.3a8.1,8.1,0,0,1-6.1,2.8,7.6,7.6,0,0,1-5.3-2,8.2,8.2,0,0,1-.6-11.4,99.9,99.9,0,0,1,7.8-8.2,3.9,3.9,0,0,0-.2-6,103.8,103.8,0,0,0-128.8,0,3.9,3.9,0,0,0-.2,6,99.9,99.9,0,0,1,7.8,8.2A8,8,0,0,1,65.3,74h0a8.1,8.1,0,0,1-6.1-2.8ZM79.2,88.2a9.6,9.6,0,0,1,2.8-.7,8.1,8.1,0,0,1,7.9,5.3,105.7,105.7,0,0,1,4.4,16.5,8,8,0,0,1-5.9,9.4,6.3,6.3,0,0,1-1.9.3h0a8,8,0,0,1-7.9-6.6,83.8,83.8,0,0,0-3.7-13.9C73.5,94.5,75.2,89.8,79.2,88.2Zm15.1,58.5a105.7,105.7,0,0,1-4.4,16.5,8.1,8.1,0,0,1-7.6,5.3h0a7,7,0,0,1-3.1-.7,8.2,8.2,0,0,1-4.3-10.3,83.8,83.8,0,0,0,3.7-13.9,8.1,8.1,0,0,1,7.7-6.6l2,.2A8.2,8.2,0,0,1,94.3,146.7Zm82.5,21.1a7,7,0,0,1-3.1.7,8.1,8.1,0,0,1-7.6-5.3,105.7,105.7,0,0,1-4.4-16.5,8.2,8.2,0,0,1,5.4-9.3,8.1,8.1,0,0,1,10.3,6.2,83.8,83.8,0,0,0,3.7,13.9A8.2,8.2,0,0,1,176.8,167.8Zm3.6-68a23.6,23.6,0,0,0-2.9,11.4,8,8,0,0,1-8,7.8l-1.4-.2c-4.3-.7-7.2-5.3-6.4-9.6A109,109,0,0,1,166,93.1a8.3,8.3,0,0,1,9.8-5.3A8,8,0,0,1,180.4,99.8Z" />
    </Svg>
  );
}

export default Baseball;
