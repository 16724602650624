/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Chats(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M232,96a16,16,0,0,0-16-16H184V48a16,16,0,0,0-16-16H40A16,16,0,0,0,24,48V176a7.9,7.9,0,0,0,4.6,7.2,7.8,7.8,0,0,0,8.4-1l35-28.3V184a16,16,0,0,0,16,16h93.6L219,230.2a7.8,7.8,0,0,0,8.4,1A7.9,7.9,0,0,0,232,224Zm-42.6,89.8a7.7,7.7,0,0,0-5-1.8H88V152h80a16,16,0,0,0,16-16V96h32V207.2Z" />
    </Svg>
  );
}

export default Chats;
