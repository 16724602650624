/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Prohibit(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M200,128a71.3,71.3,0,0,1-15.8,44.9L83.1,71.8A71.3,71.3,0,0,1,128,56,72.1,72.1,0,0,1,200,128ZM56,128a72.1,72.1,0,0,0,72,72,71.3,71.3,0,0,0,44.9-15.8L71.8,83.1A71.3,71.3,0,0,0,56,128Zm180,0A108,108,0,1,1,128,20,108.1,108.1,0,0,1,236,128Zm-20,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z" />
    </Svg>
  );
}

export default Prohibit;
