/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Line, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function AirplaneInFlight(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Line
        x1={72}
        y1={216}
        x2={216}
        y2={216}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M26.8,80H44l20,24H96L83.5,66.5A8,8,0,0,1,91.1,56H108l44,48h56a32,32,0,0,1,32,32v24H63.8a32,32,0,0,1-30.6-22.8L19.1,90.3A8,8,0,0,1,26.8,80Z"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default AirplaneInFlight;
