/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function ArrowSquareIn(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M120,144v60a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3L82,185.3,45.6,221.7A8,8,0,0,1,40,224a8.3,8.3,0,0,1-5.7-2.3,8,8,0,0,1,0-11.3L70.7,174,46.3,149.7a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,52,136h60A8,8,0,0,1,120,144ZM208,32H72A16,16,0,0,0,56,48V96a8,8,0,0,0,16,0V48H208V184H160a8,8,0,0,0,0,16h48a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Z" />
    </Svg>
  );
}

export default ArrowSquareIn;
