/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function FileImage(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M213.7,82.3l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v96a8,8,0,0,0,16,0V40h88V88a8,8,0,0,0,8,8h48V216h-8a8,8,0,0,0,0,16h8a16,16,0,0,0,16-16V88A8.1,8.1,0,0,0,213.7,82.3ZM160,80V51.3L188.7,80Zm-1.3,139.6a8.3,8.3,0,0,1,.4,8.2A8,8,0,0,1,152,232H24a8,8,0,0,1-7-4.2,7.9,7.9,0,0,1,.3-8.1l32.6-50.8a12,12,0,0,1,20.2,0l6.4,10L94,152.6a12,12,0,0,1,20,0Z" />
    </Svg>
  );
}

export default FileImage;
