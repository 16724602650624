/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function GitlabLogoSimple(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M231.9,169.8l-94.8,65.6a15.7,15.7,0,0,1-18.2,0L24.1,169.8a16.1,16.1,0,0,1-6.4-17.3L45,50a12,12,0,0,1,22.9-1.1L88.5,104h79l20.6-55.1A12,12,0,0,1,211,50l27.3,102.5A16.1,16.1,0,0,1,231.9,169.8Z" />
    </Svg>
  );
}

export default GitlabLogoSimple;
