/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Thermometer(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M212,52a28,28,0,1,0,28,28A28.1,28.1,0,0,0,212,52Zm0,40a12,12,0,1,1,12-12A12,12,0,0,1,212,92Zm-52,51.3V48a40,40,0,0,0-80,0v95.3A59.4,59.4,0,0,0,60,188a60,60,0,0,0,120,0A59.4,59.4,0,0,0,160,143.3ZM120,24a24.1,24.1,0,0,1,24,24V80H96V48A24.1,24.1,0,0,1,120,24Z" />
    </Svg>
  );
}

export default Thermometer;
