/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function HandEye(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M188,84a28.2,28.2,0,0,0-12,2.7V52a28.1,28.1,0,0,0-28-28,27.8,27.8,0,0,0-13.4,3.4A28,28,0,0,0,80,36v6.7A28,28,0,0,0,40,68v84a88,88,0,0,0,176,0V112A28.1,28.1,0,0,0,188,84Zm12,68a72,72,0,0,1-144,0V68a12,12,0,0,1,24,0v44a8,8,0,0,0,16,0V36a12,12,0,0,1,24,0v68a8,8,0,0,0,16,0V52a12,12,0,0,1,24,0v64a8,8,0,0,0,16,0v-4a12,12,0,0,1,24,0Zm-72-16c-26.7,0-43,20.6-48.8,29.4a12.1,12.1,0,0,0,0,13.2C85,187.4,101.3,208,128,208s43-20.6,48.8-29.4a12.1,12.1,0,0,0,0-13.2C171,156.6,154.7,136,128,136Zm0,52a16,16,0,1,1,16-16A16,16,0,0,1,128,188Z" />
    </Svg>
  );
}

export default HandEye;
