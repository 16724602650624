/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function SkipBackCircle(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm36,136a8,8,0,0,1-4.2,7.1,8.5,8.5,0,0,1-3.8.9,8.7,8.7,0,0,1-4.4-1.3L108,137.6V160a8,8,0,0,1-16,0V96a8,8,0,0,1,16,0v22.4l43.6-29.1a8.3,8.3,0,0,1,8.2-.4A8,8,0,0,1,164,96Z" />
    </Svg>
  );
}

export default SkipBackCircle;
