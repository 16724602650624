/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function TrademarkRegistered(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M128,56a72,72,0,1,0,72,72A72.1,72.1,0,0,0,128,56Zm28.5,110.6A8.2,8.2,0,0,1,152,168a8,8,0,0,1-6.6-3.5L131.3,144H116v16a8,8,0,0,1-16,0V96a8,8,0,0,1,8-8h24a28,28,0,0,1,15.5,51.3l11.1,16.2A7.9,7.9,0,0,1,156.5,166.6ZM144,116a12,12,0,0,1-12,12H116V104h16A12,12,0,0,1,144,116ZM128,20A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,196a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
    </Svg>
  );
}

export default TrademarkRegistered;
