/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path, Circle } from 'react-native-svg';

import { IconProps } from '../lib';

function TennisBall(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path
        d="M32,128a95.9,95.9,0,0,1,95.8-96h0A95.9,95.9,0,0,1,32,127.8Z"
        opacity={0.2}
      />
      <Path
        d="M128.2,224.2a95.9,95.9,0,0,1,95.8-96h0A95.9,95.9,0,0,1,128.2,224Z"
        opacity={0.2}
      />
      <Circle
        cx={128}
        cy={128}
        r={96}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M127.8,32A95.9,95.9,0,0,1,32,127.8"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M224,128.2A95.9,95.9,0,0,0,128.2,224"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default TennisBall;
