/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function CurrencyEur(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M192.6,189.3a8,8,0,0,1,0,11.3A80,80,0,0,1,56.4,152H40a8,8,0,0,1,0-16H56V120H40a8,8,0,0,1,0-16H56.4A80,80,0,0,1,192.6,55.4a8,8,0,0,1,0,11.3,7.9,7.9,0,0,1-11.3,0A64.1,64.1,0,0,0,72.5,104H136a8,8,0,0,1,0,16H72v16h48a8,8,0,0,1,0,16H72.5a64.1,64.1,0,0,0,108.8,37.3A7.9,7.9,0,0,1,192.6,189.3Z" />
    </Svg>
  );
}

export default CurrencyEur;
