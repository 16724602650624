/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function HouseLine(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M240,208H224V115.5a15.7,15.7,0,0,0-5.3-11.8L138.7,31a15.9,15.9,0,0,0-21.5,0l-80,72.7A16,16,0,0,0,32,115.5V208H16a8,8,0,0,0,0,16H240a8,8,0,0,0,0-16Zm-88,0H104V160a8,8,0,0,1,8-8h32a8,8,0,0,1,8,8Z" />
    </Svg>
  );
}

export default HouseLine;
