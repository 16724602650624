/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function HighlighterCircle(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M201.5,54.5a103.8,103.8,0,0,0-147,0,103.8,103.8,0,0,0,0,147,103.8,103.8,0,0,0,147,0,103.8,103.8,0,0,0,0-147ZM96,210V152h64v58a88.3,88.3,0,0,1-64,0Zm94.2-19.8A86.8,86.8,0,0,1,176,201.8V152a16,16,0,0,0-16-16V72a7.9,7.9,0,0,0-3.8-6.8,8,8,0,0,0-7.8-.4l-48,24A8.2,8.2,0,0,0,96,96v40a16,16,0,0,0-16,16v49.8A88.7,88.7,0,0,1,40,128a88,88,0,1,1,150.2,62.2Z" />
    </Svg>
  );
}

export default HighlighterCircle;
