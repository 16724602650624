/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Line } from 'react-native-svg';

import { IconProps } from '../lib';

function AlignBottom(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Rect
        x={116}
        y={100}
        width={112}
        height={56}
        rx={8}
        transform="translate(44 300) rotate(-90)"
        opacity={0.2}
      />
      <Rect x={56} y={32} width={56} height={152} rx={8} opacity={0.2} />
      <Line
        x1={216}
        y1={216}
        x2={40}
        y2={216}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Rect
        x={116}
        y={100}
        width={112}
        height={56}
        rx={8}
        transform="translate(44 300) rotate(-90)"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Rect
        x={56}
        y={32}
        width={56}
        height={152}
        rx={8}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default AlignBottom;
