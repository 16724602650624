/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function SelectionPlus(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M148,48a8,8,0,0,1-8,8H116a8,8,0,0,1,0-16h24A8,8,0,0,1,148,48Zm-8,152H116a8,8,0,0,0,0,16h24a8,8,0,0,0,0-16ZM180,56h20V76a8,8,0,0,0,16,0V56a16,16,0,0,0-16-16H180a8,8,0,0,0,0,16Zm28,52a8,8,0,0,0-8,8v24a8,8,0,0,0,16,0V116A8,8,0,0,0,208,108ZM48,148a8,8,0,0,0,8-8V116a8,8,0,0,0-16,0v24A8,8,0,0,0,48,148Zm28,52H56V180a8,8,0,0,0-16,0v20a16,16,0,0,0,16,16H76a8,8,0,0,0,0-16ZM76,40H56A16,16,0,0,0,40,56V76a8,8,0,0,0,16,0V56H76a8,8,0,0,0,0-16ZM236,200H216V180a8,8,0,0,0-16,0v20H180a8,8,0,0,0,0,16h20v20a8,8,0,0,0,16,0V216h20a8,8,0,0,0,0-16Z" />
    </Svg>
  );
}

export default SelectionPlus;
