/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Circle, Line, Polyline, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function ClockClockwise(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Circle cx={128} cy={128} r={88} opacity={0.2} fill={props.color} />
      <Line
        x1={128}
        y1={80}
        x2={128}
        y2={128}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={169.6}
        y1={152}
        x2={128}
        y2={128}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Polyline
        points="184.2 99.7 224.2 99.7 224.2 59.7"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M190.2,190.2a88,88,0,1,1,0-124.4l34,33.9"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default ClockClockwise;
