/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Circle, Path, Polyline, Line } from 'react-native-svg';

import { IconProps } from '../lib';

function NyTimesLogo(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Circle cx={172} cy={148} r={10} fill={props.color} />
      <Path
        d="M205.6,163.4A80,80,0,1,1,127.9,64"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={12}
      />
      <Path
        d="M67.2,91.9A28,28,0,0,1,70,36L186,92a28,28,0,0,0,0-56"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={12}
      />
      <Polyline
        points="48.6 154.3 127.9 112 127.9 224"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={12}
      />
      <Line
        x1={95.9}
        y1={129.1}
        x2={95.9}
        y2={217.3}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={12}
      />
    </Svg>
  );
}

export default NyTimesLogo;
