/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function CaretCircleDown(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm41.9,97.4-36,40a8,8,0,0,1-11.8,0l-36-40a8,8,0,0,1,11.8-10.8L128,144l30.1-33.4a8,8,0,1,1,11.8,10.8Z" />
    </Svg>
  );
}

export default CaretCircleDown;
