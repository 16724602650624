/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Polygon, Line, Polyline } from 'react-native-svg';

import { IconProps } from '../lib';

function BluetoothX(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Polygon points="120 32 184 80 120 128 120 32" opacity={0.2} />
      <Polygon points="120 128 184 176 120 224 120 128" opacity={0.2} />
      <Polygon
        points="120 128 184 176 120 224 120 128"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={56}
        y1={80}
        x2={120}
        y2={128}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={56}
        y1={176}
        x2={120}
        y2={128}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={232}
        y1={56}
        x2={184}
        y2={104}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={232}
        y1={104}
        x2={184}
        y2={56}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Polyline
        points="152 104 120 128 120 32 152 56"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default BluetoothX;
