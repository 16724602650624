/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, G, Path, Line } from 'react-native-svg';

import { IconProps } from '../lib';

function FlagCheckered(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <G opacity={0.2}>
        <Path d="M40,106.1c21.4-16,41-16,60-10.6V37.4C81,32,61.4,32,40,48Z" />
      </G>
      <G opacity={0.2}>
        <Path d="M156,58.6C175,64,194.6,64,216,48v58.1c-21.4,16.1-41,16-60,10.7Z" />
      </G>
      <G opacity={0.2}>
        <Path d="M156,116.8c-18.9-5.4-37.1-15.9-56-21.3v61.9c18.9,5.3,37.1,15.9,56,21.2Z" />
      </G>
      <Line
        x1={40}
        y1={216}
        x2={40}
        y2={48}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M40,168c64-48,112,48,176,0V48C152,96,104,0,40,48"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M216,106.1c-64,48-112-48-176,0"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={100}
        y1={37.4}
        x2={100}
        y2={157.4}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={156}
        y1={58.6}
        x2={156}
        y2={178.6}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default FlagCheckered;
