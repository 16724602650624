/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function GooglePhotosLogo(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M232,124H187a67.4,67.4,0,0,0,13-40,68.1,68.1,0,0,0-68-68,8,8,0,0,0-8,8V69A67.4,67.4,0,0,0,84,56a68.1,68.1,0,0,0-68,68,8,8,0,0,0,8,8H69a67.4,67.4,0,0,0-13,40,68.1,68.1,0,0,0,68,68,8,8,0,0,0,8-8V187a67.4,67.4,0,0,0,40,13,68.1,68.1,0,0,0,68-68A8,8,0,0,0,232,124ZM84,72a51.5,51.5,0,0,1,40,18.8V116H32.6A52.1,52.1,0,0,1,84,72Zm88,112a51.5,51.5,0,0,1-40-18.8V140h91.4A52.1,52.1,0,0,1,172,184Z" />
    </Svg>
  );
}

export default GooglePhotosLogo;
