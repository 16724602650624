/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function FolderSimpleLock(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M232,88v16a8,8,0,0,1-16,0V88H130.7a15.6,15.6,0,0,1-9.6-3.2L93.3,64H40V200h72a8,8,0,0,1,0,16H40a16,16,0,0,1-16-16V64A16,16,0,0,1,40,48H93.3a15.6,15.6,0,0,1,9.6,3.2L130.7,72H216A16,16,0,0,1,232,88Zm0,76v44a8,8,0,0,1-8,8H152a8,8,0,0,1-8-8V164a8,8,0,0,1,8-8h8v-4a28,28,0,0,1,56,0v4h8A8,8,0,0,1,232,164Zm-32-12a12,12,0,0,0-24,0v4h24Z" />
    </Svg>
  );
}

export default FolderSimpleLock;
