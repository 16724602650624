/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function ThumbsDown(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M237.7,157l-12-96a24.1,24.1,0,0,0-23.8-21H32A16,16,0,0,0,16,56v88a16,16,0,0,0,16,16H75l37.8,75.6A8.2,8.2,0,0,0,120,240a40.1,40.1,0,0,0,40-40V184h53.9a23.9,23.9,0,0,0,23.8-27ZM72,144H32V56H72Z" />
    </Svg>
  );
}

export default ThumbsDown;
