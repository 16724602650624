/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function ArrowFatLeft(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M114.3,229.7l-96-96a8.1,8.1,0,0,1,0-11.4l96-96a8.4,8.4,0,0,1,8.8-1.7A8,8,0,0,1,128,32V72h80a16,16,0,0,1,16,16v80a16,16,0,0,1-16,16H128v40a8,8,0,0,1-4.9,7.4A8.4,8.4,0,0,1,114.3,229.7Z" />
    </Svg>
  );
}

export default ArrowFatLeft;
