/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function ShieldStar(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M208,40H48A16,16,0,0,0,32,56v58.7c0,89.4,75.8,119.1,91,124.1a14.3,14.3,0,0,0,10,0c15.2-5,91-34.7,91-124.1V56A16,16,0,0,0,208,40Zm-39.5,91.2-27.6,9L158,163.7a7.9,7.9,0,0,1-1.8,11.1,7.6,7.6,0,0,1-4.7,1.6,8.2,8.2,0,0,1-6.5-3.3l-17-23.5-17,23.5a8.2,8.2,0,0,1-6.5,3.3,7.6,7.6,0,0,1-4.7-1.6A7.9,7.9,0,0,1,98,163.7l17.1-23.5-27.6-9a8,8,0,0,1-5.2-10A8.1,8.1,0,0,1,92.4,116l27.6,9V96a8,8,0,0,1,16,0v29l27.6-9a8.1,8.1,0,0,1,10.1,5.2A8,8,0,0,1,168.5,131.2Z" />
    </Svg>
  );
}

export default ShieldStar;
