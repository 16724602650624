/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function ArrowCircleUpLeft(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm33.7,137.7a8.2,8.2,0,0,1-11.4,0L108,119.3V148a8,8,0,0,1-16,0V100a8.5,8.5,0,0,1,.6-3.1,8.2,8.2,0,0,1,4.3-4.3A8.5,8.5,0,0,1,100,92h48a8,8,0,0,1,0,16H119.3l42.4,42.3A8.1,8.1,0,0,1,161.7,161.7Z" />
    </Svg>
  );
}

export default ArrowCircleUpLeft;
