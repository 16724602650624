/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function NumberNine(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M192,88a64,64,0,1,0-64,64,65.1,65.1,0,0,0,19.4-3l-42.5,70.9a8,8,0,0,0,2.7,11,8.7,8.7,0,0,0,4.1,1.1,8,8,0,0,0,6.9-3.9l64.7-108A63.5,63.5,0,0,0,192,88ZM80,88a48,48,0,1,1,48,48A48,48,0,0,1,80,88Z" />
    </Svg>
  );
}

export default NumberNine;
