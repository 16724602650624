/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path, Circle } from 'react-native-svg';

import { IconProps } from '../lib';

function Intersect(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path
        d="M88,160c0,2.5.5,4.8.7,7.3,2.5.2,4.8.7,7.3.7a72,72,0,0,0,72-72c0-2.5-.5-4.8-.7-7.3-2.5-.2-4.8-.7-7.3-.7A72,72,0,0,0,88,160Z"
        opacity={0.2}
      />
      <Circle
        cx={96}
        cy={96}
        r={72}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Circle
        cx={160}
        cy={160}
        r={72}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default Intersect;
