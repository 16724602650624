/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Line, Path, Circle } from 'react-native-svg';

import { IconProps } from '../lib';

function WifiSlash(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Line
        x1={48}
        y1={40}
        x2={208}
        y2={216}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={12}
      />
      <Path
        d="M107.1,57.5A143.8,143.8,0,0,1,128,56,145.6,145.6,0,0,1,230.9,98.2"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={12}
      />
      <Path
        d="M25.1,98.2A145.7,145.7,0,0,1,72.4,66.9"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={12}
      />
      <Path
        d="M152.1,107A97.5,97.5,0,0,1,197,132.1"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={12}
      />
      <Path
        d="M59,132.1A98.3,98.3,0,0,1,108,106"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={12}
      />
      <Path
        d="M92.9,166.1a50.9,50.9,0,0,1,67.6-2.4"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={12}
      />
      <Circle cx={128} cy={200} r={10} fill={props.color} />
    </Svg>
  );
}

export default WifiSlash;
