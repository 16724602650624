/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function MicrosoftPowerpointLogo(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M102,120a8,8,0,0,0-8-8H86v16h8A8,8,0,0,0,102,120Z" />
      <Path d="M136,24A104.5,104.5,0,0,0,54,64H40A16,16,0,0,0,24,80v96a16,16,0,0,0,16,16H54A104,104,0,1,0,136,24ZM70,152V104a8,8,0,0,1,8-8H94a24,24,0,0,1,0,48H86v8a8,8,0,0,1-16,0Zm58,63.6A88.7,88.7,0,0,1,75.6,192H128ZM128,64H75.6A88.7,88.7,0,0,1,128,40.4Zm16-23.6A88,88,0,0,1,223.6,120H160V80a16,16,0,0,0-16-16Zm0,175.2V192a16,16,0,0,0,16-16V136h63.6A88,88,0,0,1,144,215.6Z" />
    </Svg>
  );
}

export default MicrosoftPowerpointLogo;
