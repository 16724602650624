/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path, Line } from 'react-native-svg';

import { IconProps } from '../lib';

function HandsClapping(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path
        d="M51.8,121.9a18,18,0,1,0-31.2,18l37,64.1a72,72,0,0,0,124.7-72l-17-29.5a18,18,0,0,0-31.1,18"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M107,145.5,69,79.7a18,18,0,1,0-31.2,18l38,65.8"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M150.2,148.3l-42-72.8A18,18,0,1,0,77,93.5"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M99,67.7,88.7,49.8a18,18,0,1,0-31.2,18l2.4,4.1"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M188,191.5a72.1,72.1,0,0,0,14.1-89.4l-17-29.4a18,18,0,1,0-31.2,18"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M156.2,94.7l-28.3-49a18,18,0,1,0-31.2,18"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={176.8}
        y1={11.3}
        x2={172.7}
        y2={26.7}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={207.1}
        y1={25.4}
        x2={197.9}
        y2={38.5}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={230.6}
        y1={48.9}
        x2={217.5}
        y2={58.1}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default HandsClapping;
