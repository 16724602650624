/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function WifiMedium(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M240.6,69.6C174.3,19.5,81.7,19.5,15.4,69.5a16.1,16.1,0,0,0-6.2,11,16.6,16.6,0,0,0,3.7,12.3L115.8,214a16.1,16.1,0,0,0,12.2,5.7h0a16,16,0,0,0,12.1-5.6h0l103-121.4a16,16,0,0,0,3.7-12.2A15.6,15.6,0,0,0,240.6,69.6ZM25,82.3Zm179.5,31.2a131,131,0,0,0-17-10.7l-.8-.4a122.1,122.1,0,0,0-17.8-7.6l-1.5-.4A115.2,115.2,0,0,0,149,89.8l-1.6-.2a118.4,118.4,0,0,0-38.8,0l-1.6.2a126.7,126.7,0,0,0-18.4,4.5l-1.5.5a127,127,0,0,0-17.8,7.6l-.8.4a123.5,123.5,0,0,0-16.9,10.7h0L25.1,82.3c60.6-45.7,145.3-45.7,205.8.1Z" />
    </Svg>
  );
}

export default WifiMedium;
