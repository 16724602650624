/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function MessengerLogo(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M128,24A104,104,0,0,0,36.8,178l-8.5,30A15.9,15.9,0,0,0,48,227.7l30-8.5A104,104,0,1,0,128,24Zm53.7,93.7-32,32a8.2,8.2,0,0,1-11.4,0L112,123.3,85.7,149.7a8.1,8.1,0,0,1-11.4-11.4l32-32a8.1,8.1,0,0,1,11.4,0L144,132.7l26.3-26.4a8.1,8.1,0,0,1,11.4,11.4Z" />
    </Svg>
  );
}

export default MessengerLogo;
