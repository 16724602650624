/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function PianoKeys(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM108,152a8,8,0,0,0,8-8V48h24v96a8,8,0,0,0,8,8h8v56H100V152ZM48,48H68v96a8,8,0,0,0,8,8h8v56H48ZM208,208H172V152h8a8,8,0,0,0,8-8V48h20V208Z" />
    </Svg>
  );
}

export default PianoKeys;
