/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function ArrowLineDownLeft(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M181.7,82.3a8.1,8.1,0,0,1,0,11.4L125.3,150l44.4,44.3a8.4,8.4,0,0,1,1.7,8.8A8,8,0,0,1,164,208H64a8,8,0,0,1-8-8V100a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7L114,138.7l56.3-56.4A8.1,8.1,0,0,1,181.7,82.3ZM216,32H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
    </Svg>
  );
}

export default ArrowLineDownLeft;
