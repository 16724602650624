/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Globe(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M225.9,163.2l.3-1a103.7,103.7,0,0,0,0-68.4l-.3-1A104.2,104.2,0,0,0,128,24h0A104.2,104.2,0,0,0,30.1,92.8l-.3,1a103.7,103.7,0,0,0,0,68.4l.3,1A104.4,104.4,0,0,0,128,232h0A104.2,104.2,0,0,0,225.9,163.2ZM128,45.6A108.6,108.6,0,0,1,153.5,88h-51A108.6,108.6,0,0,1,128,45.6ZM102.5,168h51A108.6,108.6,0,0,1,128,210.4,108.6,108.6,0,0,1,102.5,168Zm-4.2-16a126.4,126.4,0,0,1,0-48h59.4a126.4,126.4,0,0,1,0,48ZM174,104h38.7a88.9,88.9,0,0,1,0,48H174a145,145,0,0,0,0-48Zm32.4-16h-36a128.7,128.7,0,0,0-24.1-46.1A88.6,88.6,0,0,1,206.4,88ZM146.3,214.1A128.7,128.7,0,0,0,170.4,168h36A88.6,88.6,0,0,1,146.3,214.1Z" />
    </Svg>
  );
}

export default Globe;
