/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Copyleft(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M128,56a72,72,0,1,0,72,72A72.1,72.1,0,0,0,128,56Zm0,120a47.7,47.7,0,0,1-38.4-19.2,8,8,0,1,1,12.8-9.6,32,32,0,1,0,0-38.4,8.1,8.1,0,0,1-11.2,1.6,8,8,0,0,1-1.6-11.2A48,48,0,1,1,128,176Zm0-156A108,108,0,1,0,236,128,108.1,108.1,0,0,0,128,20Zm0,196a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Z" />
    </Svg>
  );
}

export default Copyleft;
