/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Watch(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M175.3,63.5l-6.2-34.4A16,16,0,0,0,153.3,16H102.7A16,16,0,0,0,86.9,29.1L80.7,63.5a80,80,0,0,0,0,129l6.2,34.4A16,16,0,0,0,102.7,240h50.6a16,16,0,0,0,15.8-13.1l6.2-34.4a80,80,0,0,0,0-129ZM102.7,32h50.6l3.9,21.6a78.9,78.9,0,0,0-58.4,0Zm50.6,192H102.7l-3.9-21.6a78.9,78.9,0,0,0,58.4,0ZM168,136H128a8,8,0,0,1-8-8V88a8,8,0,0,1,16,0v32h32a8,8,0,0,1,0,16Z" />
    </Svg>
  );
}

export default Watch;
