/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path, Line } from 'react-native-svg';

import { IconProps } from '../lib';

function CellSignalSlash(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path
        d="M200,43.3V208a8,8,0,0,1-8,8H27.3a7.9,7.9,0,0,1-5.6-13.6L186.3,37.7A8,8,0,0,1,200,43.3Z"
        opacity={0.2}
      />
      <Path
        d="M135.4,88.6l50.9-50.9A8,8,0,0,1,200,43.3V159.6"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={48}
        y1={40}
        x2={208}
        y2={216}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M208,216H27.3a8,8,0,0,1-5.6-13.7l91.1-91.1"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default CellSignalSlash;
