/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path, Polyline } from 'react-native-svg';

import { IconProps } from '../lib';

function EggCrack(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path
        d="M208,152a80,80,0,0,1-160,0C48,88,96,24,128,24S208,88,208,152Z"
        opacity={0.2}
      />
      <Path
        d="M208,152a80,80,0,0,1-160,0C48,88,96,24,128,24S208,88,208,152Z"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Polyline
        points="151.4 150 158.6 112.9 129.8 101.7 172.7 53.9"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default EggCrack;
