/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Umbrella(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M239.6,126.6A112.1,112.1,0,0,0,128,24h0A112.1,112.1,0,0,0,16.4,126.6a15.7,15.7,0,0,0,4.2,12.2A15.9,15.9,0,0,0,32.3,144H120v56a32,32,0,0,0,64,0,8,8,0,0,0-16,0,16,16,0,0,1-32,0V144h87.7a15.9,15.9,0,0,0,11.7-5.2A15.7,15.7,0,0,0,239.6,126.6ZM32.3,128a95.9,95.9,0,0,1,75.9-85.9c-11.7,16-26.4,44-28,85.9Zm143.5,0c-1.6-41.9-16.3-69.9-28-85.9A95.9,95.9,0,0,1,223.7,128Z" />
    </Svg>
  );
}

export default Umbrella;
