/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function ArrowLineUpRight(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M74.3,173.7a8.1,8.1,0,0,1,0-11.4L130.7,106,86.3,61.7a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,92,48H192a8,8,0,0,1,8,8V156a8,8,0,0,1-4.9,7.4,8.5,8.5,0,0,1-3.1.6,8.3,8.3,0,0,1-5.7-2.3L142,117.3,85.7,173.7a8.2,8.2,0,0,1-11.4,0ZM216,208H40a8,8,0,0,0,0,16H216a8,8,0,0,0,0-16Z" />
    </Svg>
  );
}

export default ArrowLineUpRight;
