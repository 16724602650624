/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function FolderNotchPlus(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M216,72H130.7L102.9,51.2A15.6,15.6,0,0,0,93.3,48H40A16,16,0,0,0,24,64V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V88A16,16,0,0,0,216,72ZM40,96V64H93.3l21.4,16L93.3,96Zm112,60H136v16a8,8,0,0,1-16,0V156H104a8,8,0,0,1,0-16h16V124a8,8,0,0,1,16,0v16h16a8,8,0,0,1,0,16Z" />
    </Svg>
  );
}

export default FolderNotchPlus;
