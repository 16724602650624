/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function CurrencyEth(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M222.3,123.1l-88-112a8,8,0,0,0-12.6,0l-88,112a7.8,7.8,0,0,0,0,9.8l88,112a8,8,0,0,0,12.6,0l88-112A7.8,7.8,0,0,0,222.3,123.1ZM136,155.6V39.1l67.4,85.8Zm-16,0L52.6,124.9,120,39.1Zm0,17.6v43.7l-53.4-68Z" />
    </Svg>
  );
}

export default CurrencyEth;
