/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function CaretCircleUp(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm41.4,125.9A8.2,8.2,0,0,1,164,152a7.9,7.9,0,0,1-5.9-2.6L128,116,97.9,149.4a8,8,0,0,1-11.8-10.8l36-40a8.3,8.3,0,0,1,11.8,0l36,40A8,8,0,0,1,169.4,149.9Z" />
    </Svg>
  );
}

export default CaretCircleUp;
