/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function MaskHappy(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M217,35.1a15.9,15.9,0,0,0-14.8-1.7C188.2,38.9,159.8,48,128,48S67.8,38.9,53.8,33.4A16,16,0,0,0,32,48.3V104c0,35.8,9.7,69.5,27.2,95s42.6,41,68.8,41,50.7-14.5,68.8-41S224,139.8,224,104V48.3A15.9,15.9,0,0,0,217,35.1ZM80.1,133.3a8,8,0,0,1-11.3.7,8,8,0,0,1-.6-11.3,31.8,31.8,0,0,1,47.6,0,8,8,0,0,1-.6,11.3,8.1,8.1,0,0,1-5.3,2,8,8,0,0,1-6-2.7,16,16,0,0,0-23.8,0ZM159,182.7a56.3,56.3,0,0,1-62,0,8.1,8.1,0,0,1-2.2-11.1,8,8,0,0,1,11.1-2.3,39.8,39.8,0,0,0,44.2,0,8,8,0,0,1,11.1,2.3A8.1,8.1,0,0,1,159,182.7ZM187.2,134a8.1,8.1,0,0,1-5.3,2,8,8,0,0,1-6-2.7,16,16,0,0,0-23.8,0,8,8,0,0,1-11.3.7,8,8,0,0,1-.6-11.3,31.8,31.8,0,0,1,47.6,0A8,8,0,0,1,187.2,134Z" />
    </Svg>
  );
}

export default MaskHappy;
