/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function MagnifyingGlassPlus(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M229.6,218.3l-43.2-43.2a92.2,92.2,0,1,0-11.3,11.3l43.2,43.3A8.3,8.3,0,0,0,224,232a8,8,0,0,0,5.6-13.7ZM148,124H124v24a8,8,0,0,1-16,0V124H84a8,8,0,0,1,0-16h24V84a8,8,0,0,1,16,0v24h24a8,8,0,0,1,0,16Z" />
    </Svg>
  );
}

export default MagnifyingGlassPlus;
