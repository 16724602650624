/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function MusicNotesSimple(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M212.9,25.7a7.9,7.9,0,0,0-6.8-1.5l-128,32A8,8,0,0,0,72,64V174.1A35.3,35.3,0,0,0,52,168a36,36,0,1,0,36,36V70.2l112-28v99.9a35.3,35.3,0,0,0-20-6.1,36,36,0,1,0,36,36V32A7.8,7.8,0,0,0,212.9,25.7Z" />
    </Svg>
  );
}

export default MusicNotesSimple;
