/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Upload(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M74.3,77.7a8.1,8.1,0,0,1,0-11.4l48-48a8.1,8.1,0,0,1,11.4,0l48,48a8.1,8.1,0,0,1,0,11.4,8.2,8.2,0,0,1-11.4,0L136,43.3V128a8,8,0,0,1-16,0V43.3L85.7,77.7A8.1,8.1,0,0,1,74.3,77.7ZM240,136v64a16,16,0,0,1-16,16H32a16,16,0,0,1-16-16V136a16,16,0,0,1,16-16h68a4,4,0,0,1,4,4v3.5c0,13.4,11,24.8,24.5,24.5A24,24,0,0,0,152,128v-4a4,4,0,0,1,4-4h68A16,16,0,0,1,240,136Zm-40,32a12,12,0,1,0-12,12A12,12,0,0,0,200,168Z" />
    </Svg>
  );
}

export default Upload;
