/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, G, Line } from 'react-native-svg';

import { IconProps } from '../lib';

function PlugsConnected(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <G opacity={0.2}>
        <Rect
          x={60.1}
          y={88.4}
          width={135.8}
          height={79.2}
          rx={24}
          transform="translate(-53 128) rotate(-45)"
        />
        <Rect
          x={60.1}
          y={88.4}
          width={135.8}
          height={79.2}
          rx={24}
          transform="translate(-53 128) rotate(-45)"
        />
      </G>
      <Line
        x1={80}
        y1={176}
        x2={24}
        y2={232}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={232}
        y1={24}
        x2={176}
        y2={80}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Rect
        x={60.1}
        y={88.4}
        width={135.8}
        height={79.2}
        rx={24}
        transform="translate(-53 128) rotate(-45)"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={92}
        y1={92}
        x2={164}
        y2={164}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={224}
        y1={160}
        x2={200}
        y2={152}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={56}
        y1={104}
        x2={32}
        y2={96}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={96}
        y1={32}
        x2={104}
        y2={56}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={152}
        y1={200}
        x2={160}
        y2={224}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default PlugsConnected;
