/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Circle } from 'react-native-svg';

import { IconProps } from '../lib';

function FilmScript(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Rect x={48} y={32} width={160} height={192} rx={8} opacity={0.2} />
      <Rect
        x={48}
        y={32}
        width={160}
        height={192}
        rx={8}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Circle cx={84} cy={80} r={12} fill={props.color} />
      <Circle cx={84} cy={176} r={12} fill={props.color} />
      <Circle cx={84} cy={128} r={12} fill={props.color} />
    </Svg>
  );
}

export default FilmScript;
