/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Receipt(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M216,40H40A16,16,0,0,0,24,56V208a7.9,7.9,0,0,0,3.8,6.8,8,8,0,0,0,7.8.4L64,200.9l28.4,14.3a8.3,8.3,0,0,0,7.2,0L128,200.9l28.4,14.3a8.5,8.5,0,0,0,7.2,0L192,200.9l28.4,14.3a8,8,0,0,0,7.8-.4A7.9,7.9,0,0,0,232,208V56A16,16,0,0,0,216,40ZM180,144H76a8,8,0,0,1,0-16H180a8,8,0,0,1,0,16Zm0-32H76a8,8,0,0,1,0-16H180a8,8,0,0,1,0,16Z" />
    </Svg>
  );
}

export default Receipt;
