/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Alien(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M128,16a96.2,96.2,0,0,0-96,96c0,24,12.6,55.1,33.6,83s44.5,45,62.4,45,41.2-16.8,62.4-45S224,136,224,112A96.2,96.2,0,0,0,128,16ZM64,116v-4a12,12,0,0,1,12-12,36,36,0,0,1,36,36v4a12,12,0,0,1-12,12A36,36,0,0,1,64,116Zm80,84H112a8,8,0,0,1,0-16h32a8,8,0,0,1,0,16Zm48-84a36,36,0,0,1-36,36,12,12,0,0,1-12-12v-4a36,36,0,0,1,36-36,12,12,0,0,1,12,12Z" />
    </Svg>
  );
}

export default Alien;
