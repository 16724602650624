/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Cake(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M206,80H136V69.4c11.5-5.1,18.2-12.5,20.1-22C160.2,26,136,4.3,133.2,2a7.8,7.8,0,0,0-10.3-.1C120,4.2,95.1,25.7,99.1,47.2c1.8,9.7,8.9,17.2,20.9,22.3V80H50a32.1,32.1,0,0,0-32,32v13.3A43.3,43.3,0,0,0,30.9,156l1.1,1v51a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V157l1.1-1A43.3,43.3,0,0,0,238,125.3V112A32.1,32.1,0,0,0,206,80Zm2,128H48V166.2A39.3,39.3,0,0,0,60.8,168,42.2,42.2,0,0,0,94,150.6a41.9,41.9,0,0,0,68,0A42.2,42.2,0,0,0,195.2,168h.8a40.6,40.6,0,0,0,12-1.8Z" />
    </Svg>
  );
}

export default Cake;
