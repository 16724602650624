/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Flask(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M223.6,199.8,160,93.8V40h8a8,8,0,0,0,0-16H88a8,8,0,0,0,0,16h8V93.8L55.8,160.7c0,.1-.1.2-.1.3L32.4,199.8A16,16,0,0,0,46.1,224H209.9a16,16,0,0,0,13.7-24.2ZM109.7,102a15.9,15.9,0,0,0,2.3-8.2V40h32V93.8a15.9,15.9,0,0,0,2.3,8.2l38.8,64.8c-13.1,7.1-31.4,5.1-53.5-6-14.3-7.1-32.7-13.5-50.3-11.4Z" />
    </Svg>
  );
}

export default Flask;
