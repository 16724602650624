/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function ArrowSquareDown(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM167.6,139.7l-33.9,33.9a8,8,0,0,1-2.7,1.8,7.7,7.7,0,0,1-3,.6,8.5,8.5,0,0,1-3.1-.6,8.7,8.7,0,0,1-2.6-1.8L88.4,139.7a8,8,0,0,1,11.3-11.3L120,148.7V88a8,8,0,0,1,16,0v60.7l20.3-20.3a8,8,0,0,1,11.3,11.3Z" />
    </Svg>
  );
}

export default ArrowSquareDown;
