/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function GenderIntersex(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M208,16H168a8,8,0,0,0,0,16h20.7L164.3,56.4A68,68,0,1,0,112,175.5V196H88a8,8,0,0,0,0,16h24v20a8,8,0,0,0,16,0V212h24a8,8,0,0,0,0-16H128V175.5A67.9,67.9,0,0,0,175.1,68.2L200,43.3V64a8,8,0,0,0,16,0V24A8,8,0,0,0,208,16ZM120,160a52,52,0,1,1,52-52A52,52,0,0,1,120,160Z" />
    </Svg>
  );
}

export default GenderIntersex;
