/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path, Line } from 'react-native-svg';

import { IconProps } from '../lib';

function SnapchatLogo(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path
        d="M18.5,188.7S72,163.9,72,80a56,56,0,0,1,112,0c0,83.9,53.5,108.7,53.5,108.7-9.3,8.7-29,3.4-40.2,9.5s-17,25.6-29.5,28.8S141,216,128,216s-27.7,14.1-39.8,11-18.5-22.7-29.5-28.8S27.8,197.4,18.5,188.7Z"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={189.3}
        y1={122.7}
        x2={216}
        y2={112}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={66.7}
        y1={122.7}
        x2={40}
        y2={112}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default SnapchatLogo;
