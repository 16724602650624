/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Polygon(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M230.6,49.4a31.9,31.9,0,0,0-45.2,0h0a30.2,30.2,0,0,0-5.2,6.7L152,48.4a32,32,0,0,0-54.6-23h0a32,32,0,0,0-5.8,37.4L57.7,93.3a32,32,0,0,0-40.3,4.1h0a31.9,31.9,0,0,0,0,45.2A31.6,31.6,0,0,0,40,152a32.1,32.1,0,0,0,20.3-7.2l70,51.3a32,32,0,0,0,7.1,34.5,31.9,31.9,0,0,0,45.2,0,31.9,31.9,0,0,0,0-45.2,43.3,43.3,0,0,0-4.7-4l27.3-77.5H208a31.6,31.6,0,0,0,22.6-9.4A31.9,31.9,0,0,0,230.6,49.4ZM162.8,176.1a32.4,32.4,0,0,0-23.1,7.1l-70-51.3a32.4,32.4,0,0,0-1.3-26.7l33.9-30.5A32.4,32.4,0,0,0,120,80a31.6,31.6,0,0,0,22.6-9.4,30.2,30.2,0,0,0,5.2-6.7L176,71.6a31.6,31.6,0,0,0,9.4,23,43.3,43.3,0,0,0,4.7,4Z" />
    </Svg>
  );
}

export default Polygon;
