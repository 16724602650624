/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Terminal(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M120,128a8,8,0,0,0-2.7-6l-72-64A8,8,0,1,0,34.7,70L100,128,34.7,186A8,8,0,0,0,40,200a8.1,8.1,0,0,0,5.3-2l72-64A8,8,0,0,0,120,128Z" />
      <Path d="M216,184H120a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Z" />
    </Svg>
  );
}

export default Terminal;
