/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function CaretDoubleUp(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M147.3,136H208a8,8,0,0,0,7.4-4.9,8.4,8.4,0,0,0-1.7-8.8l-80-80a8.1,8.1,0,0,0-11.4,0l-80,80a8.4,8.4,0,0,0-1.7,8.8A8,8,0,0,0,48,136h60.7L42.3,202.3a8.4,8.4,0,0,0-1.7,8.8A8,8,0,0,0,48,216H208a8,8,0,0,0,7.4-4.9,8.4,8.4,0,0,0-1.7-8.8Z" />
    </Svg>
  );
}

export default CaretDoubleUp;
