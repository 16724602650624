/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function WifiNone(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path
        d="M20.2,75.9C83.7,28,172.3,28,235.8,75.9A8.1,8.1,0,0,1,237,87.5c-19,22.5-83,97.8-103,121.4a7.9,7.9,0,0,1-12,0C102,185.3,38,110,19,87.5A8.1,8.1,0,0,1,20.2,75.9Z"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default WifiNone;
