/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Textbox(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M248,80v96a16,16,0,0,1-16,16H144a8,8,0,0,1-8-8V72a8,8,0,0,1,8-8h88A16,16,0,0,1,248,80ZM120,48V208a8,8,0,0,1-16,0V192H24A16,16,0,0,1,8,176V80A16,16,0,0,1,24,64h80V48a8,8,0,0,1,16,0ZM86,112a8,8,0,0,0-8-8H50a8,8,0,0,0,0,16h6v28a8,8,0,0,0,16,0V120h6A8,8,0,0,0,86,112Z" />
    </Svg>
  );
}

export default Textbox;
