/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path, Line } from 'react-native-svg';

import { IconProps } from '../lib';

function Football(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path
        d="M184.6,184.6c-41.6,41.5-99.2,38.2-127,33.5a24,24,0,0,1-19.7-19.7c-4.7-27.8-8-85.4,33.5-127s99.2-38.2,127-33.5a24,24,0,0,1,19.7,19.7C222.8,85.4,226.1,143,184.6,184.6Z"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={160}
        y1={96}
        x2={96}
        y2={160}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={124.7}
        y1={102.1}
        x2={153.9}
        y2={131.3}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={102.1}
        y1={124.7}
        x2={131.3}
        y2={153.9}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={148.2}
        y1={36.2}
        x2={219.8}
        y2={107.8}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={36.2}
        y1={148.2}
        x2={107.8}
        y2={219.8}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M36.2,148.2C39,123,48.2,94.7,71.4,71.4S123,39,148.2,36.2h0l71.6,71.6h0c-2.8,25.2-12,53.5-35.2,76.8S133,217,107.8,219.8Z"
        opacity={0.2}
      />
    </Svg>
  );
}

export default Football;
