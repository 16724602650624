/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function UserList(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M144,80a8,8,0,0,1,8-8h96a8,8,0,0,1,0,16H152A8,8,0,0,1,144,80Zm104,40H152a8,8,0,0,0,0,16h96a8,8,0,0,0,0-16Zm0,48H176a8,8,0,0,0,0,16h72a8,8,0,0,0,0-16ZM109.1,142.1a48,48,0,1,0-58.2,0A72,72,0,0,0,10.3,190a8.1,8.1,0,0,0,1.4,6.9A7.8,7.8,0,0,0,18,200H142a7.8,7.8,0,0,0,6.3-3.1,8.1,8.1,0,0,0,1.4-6.9A72,72,0,0,0,109.1,142.1Z" />
    </Svg>
  );
}

export default UserList;
