/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function FilmSlate(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M216,104H100.8L212.1,74.2a7.9,7.9,0,0,0,4.8-3.8,7.5,7.5,0,0,0,.8-6l-8.3-30.9a15.8,15.8,0,0,0-19.5-11.3L35.3,63.6A15.9,15.9,0,0,0,24,83.2l8,29.9V200a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V112A8,8,0,0,0,216,104ZM169.3,69.1,99.7,87.7l-29.3-17,69.5-18.6ZM208,200H48V120H208v80Z" />
    </Svg>
  );
}

export default FilmSlate;
