/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Eyeglasses(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M232,72v92a44,44,0,0,1-87.8,4H111.8A44,44,0,0,1,24,164V72A32.1,32.1,0,0,1,56,40a8,8,0,0,1,0,16A16,16,0,0,0,40,72v58.1A43.9,43.9,0,0,1,110.3,152h35.4A43.9,43.9,0,0,1,216,130.1V72a16,16,0,0,0-16-16,8,8,0,0,1,0-16A32.1,32.1,0,0,1,232,72Z" />
    </Svg>
  );
}

export default Eyeglasses;
