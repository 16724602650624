/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Sliders(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M136,65.4V40a8,8,0,0,0-16,0V65.4a24,24,0,0,0,0,45.2V216a8,8,0,0,0,16,0V110.6a24,24,0,0,0,0-45.2Z" />
      <Path d="M224,168a24,24,0,0,0-16-22.6V40a8,8,0,0,0-16,0V145.4a24,24,0,0,0,0,45.2V216a8,8,0,0,0,16,0V190.6A24,24,0,0,0,224,168Z" />
      <Path d="M64,113.4V40a8,8,0,0,0-16,0v73.4a24,24,0,0,0,0,45.2V216a8,8,0,0,0,16,0V158.6a24,24,0,0,0,0-45.2Z" />
    </Svg>
  );
}

export default Sliders;
