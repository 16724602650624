/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path, Circle } from 'react-native-svg';

import { IconProps } from '../lib';

function Bandaids(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path
        d="M206.6,94.6a32,32,0,0,0-45.2-45.2L128,82.7,173.3,128Z"
        opacity={0.2}
      />
      <Path
        d="M49.4,161.4a32,32,0,0,0,45.2,45.2L128,173.3,82.7,128Z"
        opacity={0.2}
      />
      <Path
        d="M94.6,49.4A32,32,0,0,0,49.4,94.6L82.7,128,128,82.7Z"
        opacity={0.2}
      />
      <Path
        d="M173.3,128,128,173.3l33.4,33.3a32,32,0,0,0,45.2-45.2Z"
        opacity={0.2}
      />
      <Path
        d="M49.4,94.6A32,32,0,0,1,94.6,49.4l112,112a32,32,0,0,1-45.2,45.2Z"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M161.4,49.4a32,32,0,0,1,45.2,45.2l-112,112a32,32,0,0,1-45.2-45.2Z"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Circle
        cx={128}
        cy={128}
        r={4}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
        opacity={0.5}
      />
      <Circle cx={128} cy={128} r={12} fill={props.color} />
    </Svg>
  );
}

export default Bandaids;
