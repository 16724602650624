/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path, Circle } from 'react-native-svg';

import { IconProps } from '../lib';

function Flower(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path
        d="M115,103.2a28,28,0,0,1,26,0h0c7.3-15.4,15-34.6,15-47.2a28,28,0,0,0-56,0c0,12.6,7.7,31.8,15,47.2Z"
        opacity={0.2}
      />
      <Path
        d="M100,126.8a27.8,27.8,0,0,1,3.8-12.8,26.5,26.5,0,0,1,9.2-9.6h0C103.3,90.3,90.5,74,79.6,67.8a28,28,0,0,0-28,48.4c10.9,6.3,31.4,9.3,48.4,10.6Z"
        opacity={0.2}
      />
      <Path
        d="M113,151.6a26.5,26.5,0,0,1-9.2-9.6,27.8,27.8,0,0,1-3.8-12.8h0c-17,1.3-37.5,4.3-48.4,10.6a28,28,0,1,0,28,48.4c10.9-6.2,23.7-22.5,33.4-36.6Z"
        opacity={0.2}
      />
      <Path
        d="M141,152.8a28,28,0,0,1-26,0h0c-7.3,15.4-15,34.6-15,47.2a28,28,0,0,0,56,0c0-12.6-7.7-31.8-15-47.2Z"
        opacity={0.2}
      />
      <Path
        d="M156,129.2a27.8,27.8,0,0,1-3.8,12.8,26.5,26.5,0,0,1-9.2,9.6h0c9.7,14.1,22.5,30.4,33.4,36.6a28,28,0,0,0,28-48.4c-10.9-6.3-31.4-9.3-48.4-10.6Z"
        opacity={0.2}
      />
      <Path
        d="M143,104.4a26.5,26.5,0,0,1,9.2,9.6,27.8,27.8,0,0,1,3.8,12.8h0c17-1.3,37.5-4.3,48.4-10.6a28,28,0,0,0-28-48.4C165.5,74,152.7,90.3,143,104.4Z"
        opacity={0.2}
      />
      <Circle
        cx={128}
        cy={128}
        r={28}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M115,103.2c-7.3-15.4-15-34.6-15-47.2a28,28,0,0,1,56,0c0,12.6-7.7,31.8-15,47.2"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M100,126.8c-17-1.3-37.5-4.3-48.4-10.6a28,28,0,0,1,28-48.4C90.5,74,103.3,90.3,113,104.4"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M113,151.6c-9.7,14.1-22.5,30.4-33.4,36.6a28,28,0,1,1-28-48.4c10.9-6.3,31.4-9.3,48.4-10.6"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M141,152.8c7.3,15.4,15,34.6,15,47.2a28,28,0,0,1-56,0c0-12.6,7.7-31.8,15-47.2"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M156,129.2c17,1.3,37.5,4.3,48.4,10.6a28,28,0,0,1-28,48.4c-10.9-6.2-23.7-22.5-33.4-36.6"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M143,104.4c9.7-14.1,22.5-30.4,33.4-36.6a28,28,0,0,1,28,48.4c-10.9,6.3-31.4,9.3-48.4,10.6"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default Flower;
