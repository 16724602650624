/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function ApplePodcastsLogo(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M128,144a28,28,0,1,1,28-28A28.1,28.1,0,0,1,128,144Zm12.5,0h-25a19.9,19.9,0,0,0-15.4,7.3,19.6,19.6,0,0,0-4.2,16.6l9.6,48A20.1,20.1,0,0,0,125.1,232h5.8a20.1,20.1,0,0,0,19.6-16.1l9.6-48a19.6,19.6,0,0,0-4.2-16.6A19.9,19.9,0,0,0,140.5,144Zm39.7,4.3a8,8,0,0,0,14.9,5.8A70.7,70.7,0,0,0,200,128a72,72,0,0,0-144,0,70.3,70.3,0,0,0,4.9,26.1,8,8,0,0,0,7.4,5.1,7.2,7.2,0,0,0,2.9-.6,8,8,0,0,0,4.6-10.3,56,56,0,1,1,104.4,0ZM128,24A104,104,0,0,0,72,215.7a8,8,0,0,0,8.7-13.5,88,88,0,1,1,94.6,0,8,8,0,0,0,4.4,14.7,8.8,8.8,0,0,0,4.3-1.2A104,104,0,0,0,128,24Z" />
    </Svg>
  );
}

export default ApplePodcastsLogo;
