/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Eraser(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M216,207.8H130.3l34.8-34.7h0l56.6-56.6a24.1,24.1,0,0,0,0-33.9L176.4,37.3a24,24,0,0,0-33.9,0L85.9,93.9h0L29.3,150.5a24,24,0,0,0,0,33.9l37.1,37.1a7.9,7.9,0,0,0,5.7,2.3H216a8,8,0,0,0,0-16ZM153.8,48.6a8.1,8.1,0,0,1,11.3,0l45.2,45.3a7.9,7.9,0,0,1,0,11.3l-50.9,50.9L102.9,99.5Z" />
    </Svg>
  );
}

export default Eraser;
