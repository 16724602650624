/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function NumberSeven(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M112,240a7.3,7.3,0,0,1-2.5-.4,8,8,0,0,1-5.1-10.1L164.9,48H80a8,8,0,0,1,0-16h96a7.9,7.9,0,0,1,6.5,3.3,8.1,8.1,0,0,1,1.1,7.2l-64,192A7.9,7.9,0,0,1,112,240Z" />
    </Svg>
  );
}

export default NumberSeven;
