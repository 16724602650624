/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Sticker(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M168,32H88A56,56,0,0,0,32,88v80a56,56,0,0,0,56,56h48a6.9,6.9,0,0,0,2.5-.4c26.3-8.8,76.3-58.8,85.1-85.1a6.9,6.9,0,0,0,.4-2.5V88A56,56,0,0,0,168,32ZM136,207.4V176a40,40,0,0,1,40-40h31.4C198.2,157.6,157.6,198.2,136,207.4Z" />
    </Svg>
  );
}

export default Sticker;
