/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path, Line } from 'react-native-svg';

import { IconProps } from '../lib';

function MicrosoftTeamsLogo(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Rect x={32} y={72} width={120} height={112} rx={8} opacity={0.2} />
      <Rect
        x={32}
        y={72}
        width={120}
        height={112}
        rx={8}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M152,88h32a8,8,0,0,1,8,8v72a56,56,0,0,1-56,56h0a56.1,56.1,0,0,1-53.7-40"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={110}
        y1={104}
        x2={74}
        y2={104}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={92}
        y1={152}
        x2={92}
        y2={104}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M108.3,72A32,32,0,1,1,152,83.7"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M166.1,45.2a26,26,0,1,1-3.4,28.4"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M192,88h27.3a4.7,4.7,0,0,1,4.7,4.7V152a32,32,0,0,1-32,32h-2.3"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default MicrosoftTeamsLogo;
