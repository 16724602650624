/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Disc(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M188.3,43.3l-.6-.5-.8-.4A102.9,102.9,0,0,0,128,24a104.1,104.1,0,1,0,60.3,19.3ZM128,152a24,24,0,1,1,24-24A24.1,24.1,0,0,1,128,152Zm88-24c0,2.5-.1,4.9-.3,7.3L168,126.9a39.8,39.8,0,0,0-11-26.4l27.8-39.7A87.9,87.9,0,0,1,216,128Z" />
    </Svg>
  );
}

export default Disc;
