/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function PaintBrushBroad(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M216,24H72A40,40,0,0,0,32,64v72a24.1,24.1,0,0,0,24,24h46.8l-6.7,46.9A3.7,3.7,0,0,0,96,208a32,32,0,0,0,64,0,3.7,3.7,0,0,0-.1-1.1L153.2,160H200a24.1,24.1,0,0,0,24-24V32A8,8,0,0,0,216,24ZM72,40H176V80a8,8,0,0,0,16,0V40h16v64H48V64A24.1,24.1,0,0,1,72,40Z" />
    </Svg>
  );
}

export default PaintBrushBroad;
