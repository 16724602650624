/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function CurrencyNgn(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M216,136H200V120h16a8,8,0,0,0,0-16H200V46a8,8,0,0,0-16,0v58H119.4L70.3,41.1A8,8,0,0,0,56,46v58H40a8,8,0,0,0,0,16H56v16H40a8,8,0,0,0,0,16H56v58a8,8,0,0,0,16,0V152h64.6l49.1,62.9a8.1,8.1,0,0,0,8.9,2.7A8.1,8.1,0,0,0,200,210V152h16a8,8,0,0,0,0-16Zm-32-16v16H144.4l-12.5-16ZM72,69.3,99.1,104H72ZM72,136V120h39.6l12.5,16Zm112,50.7L156.9,152H184Z" />
    </Svg>
  );
}

export default CurrencyNgn;
