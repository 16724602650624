/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Detective(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M216,180a36,36,0,0,1-71.1,8H111.1a36,36,0,1,1,0-16h33.8a36,36,0,0,1,71.1,8Zm32-68H220.2L173.3,45a16.1,16.1,0,0,0-26.5.3L128,73.6,109.2,45.3A16.1,16.1,0,0,0,82.7,45L35.8,112H8a8,8,0,0,0,0,16H248a8,8,0,0,0,0-16Z" />
    </Svg>
  );
}

export default Detective;
