/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Line, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function FlyingSaucer(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Line
        x1={168.8}
        y1={188.7}
        x2={172.7}
        y2={212.3}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={12}
      />
      <Line
        x1={128}
        y1={192}
        x2={128}
        y2={224}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={12}
      />
      <Line
        x1={87.2}
        y1={188.7}
        x2={83.3}
        y2={212.3}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={12}
      />
      <Path
        d="M177,68.8c37.3,7.8,63,24.2,63,43.2,0,26.5-50.1,48-112,48S16,138.5,16,112c0-19,25.9-35.5,63.4-43.2"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={12}
      />
      <Path
        d="M72,99.9a15.8,15.8,0,0,0,12.4,15.5A191.4,191.4,0,0,0,128,120a191.4,191.4,0,0,0,43.6-4.6A15.8,15.8,0,0,0,184,99.9V96a56,56,0,0,0-56.7-56C96.5,40.4,72,66.1,72,96.8Z"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={12}
      />
    </Svg>
  );
}

export default FlyingSaucer;
