/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function ArrowsVertical(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M167.4,196.9a8.4,8.4,0,0,1-1.7,8.8l-32,32a8.2,8.2,0,0,1-11.4,0l-32-32a8.4,8.4,0,0,1-1.7-8.8A8,8,0,0,1,96,192h24V64H96a8,8,0,0,1-7.4-4.9,8.4,8.4,0,0,1,1.7-8.8l32-32a8.1,8.1,0,0,1,11.4,0l32,32a8.4,8.4,0,0,1,1.7,8.8A8,8,0,0,1,160,64H136V192h24A8,8,0,0,1,167.4,196.9Z" />
    </Svg>
  );
}

export default ArrowsVertical;
