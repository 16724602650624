/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function CaretCircleLeft(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm21.4,134.1a8,8,0,0,1,.5,11.3A7.9,7.9,0,0,1,144,172a8.2,8.2,0,0,1-5.4-2.1l-40-36a8,8,0,0,1,0-11.8l40-36a8,8,0,1,1,10.8,11.8L116,128Z" />
    </Svg>
  );
}

export default CaretCircleLeft;
