/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function ArrowDownLeft(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M197.7,69.7,127.3,140l46.4,46.3a8.4,8.4,0,0,1,1.7,8.8A8,8,0,0,1,168,200H64a8,8,0,0,1-8-8V88a8,8,0,0,1,4.9-7.4,8.4,8.4,0,0,1,8.8,1.7L116,128.7l70.3-70.4a8.1,8.1,0,0,1,11.4,11.4Z" />
    </Svg>
  );
}

export default ArrowDownLeft;
