/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function NumberSix(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M128,104a66.3,66.3,0,0,0-19.5,3l42.4-70.9a8,8,0,0,0-13.8-8.2l-64.5,108-.2.5A63,63,0,0,0,64,168a64,64,0,1,0,64-64Zm0,112a48.1,48.1,0,0,1-41.2-72.7l.2-.3a48,48,0,1,1,41,73Z" />
    </Svg>
  );
}

export default NumberSix;
