/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Paperclip(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M209.7,122.3a8.1,8.1,0,0,1-.1,11.4l-82,81.9a56,56,0,0,1-79.2-79.2L147.7,35.8a40,40,0,1,1,56.6,56.5L105,192.9A23.8,23.8,0,0,1,88,200a24,24,0,0,1-17-41l83.3-84.6a8,8,0,1,1,11.4,11.2L82.4,170.3A8,8,0,0,0,80,176a8.1,8.1,0,0,0,13.7,5.7L192.9,81A23.8,23.8,0,0,0,200,64a24,24,0,0,0-41-17L59.8,147.7a40,40,0,1,0,56.5,56.6l82-82A8.1,8.1,0,0,1,209.7,122.3Z" />
    </Svg>
  );
}

export default Paperclip;
