/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function CaretCircleDoubleDown(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M54.5,54.5a104,104,0,1,0,147,0A103.9,103.9,0,0,0,54.5,54.5Zm99.8,83.8a8.1,8.1,0,0,1,11.4,0,8.2,8.2,0,0,1,0,11.4l-32,32a8.1,8.1,0,0,1-11.4,0l-32-32a8.1,8.1,0,0,1,11.4-11.4L128,164.7Zm0-56a8.1,8.1,0,0,1,11.4,0,8.2,8.2,0,0,1,0,11.4l-32,32a8.1,8.1,0,0,1-11.4,0l-32-32a8.1,8.1,0,0,1,11.4-11.4L128,108.7Z" />
    </Svg>
  );
}

export default CaretCircleDoubleDown;
