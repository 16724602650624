/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path, Line, Circle } from 'react-native-svg';

import { IconProps } from '../lib';

function BugDroid(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M47.9,128h160v20a80,80,0,0,1-160,0Z" opacity={0.2} />
      <Path
        d="M207.9,148a80,80,0,0,1-160,0V108a80,80,0,0,1,160,0Z"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={207.9}
        y1={128}
        x2={47.9}
        y2={128}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Circle cx={155.9} cy={92} r={12} fill={props.color} />
      <Circle cx={99.9} cy={92} r={12} fill={props.color} />
      <Line
        x1={55.9}
        y1={28}
        x2={76.2}
        y2={46.9}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={199.9}
        y1={28}
        x2={179.6}
        y2={46.9}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default BugDroid;
