/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function SmileySad(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm36,72a12,12,0,1,1-12,12A12,12,0,0,1,164,96ZM92,96a12,12,0,1,1-12,12A12,12,0,0,1,92,96Zm81.6,86.9a7.6,7.6,0,0,1-4,1.1,7.7,7.7,0,0,1-6.9-4,40.1,40.1,0,0,0-69.4,0,8,8,0,1,1-13.8-8,56,56,0,0,1,97,0A8,8,0,0,1,173.6,182.9Z" />
    </Svg>
  );
}

export default SmileySad;
