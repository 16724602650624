/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function CurrencyDollarSimple(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M200,168a48,48,0,0,1-48,48H136v16a8,8,0,0,1-16,0V216H104a48,48,0,0,1-48-48,8,8,0,0,1,16,0,32.1,32.1,0,0,0,32,32h48a32,32,0,0,0,0-64H108a48,48,0,0,1,0-96h12V24a8,8,0,0,1,16,0V40h8a48,48,0,0,1,48,48,8,8,0,0,1-16,0,32.1,32.1,0,0,0-32-32H108a32,32,0,0,0,0,64h44A48,48,0,0,1,200,168Z" />
    </Svg>
  );
}

export default CurrencyDollarSimple;
