/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function HourglassMedium(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M193.6,88.4A16.1,16.1,0,0,0,200,75.6V40a16,16,0,0,0-16-16H72A16,16,0,0,0,56,40V76a16.1,16.1,0,0,0,6.4,12.8l18.1,13.6h0L114.7,128,62.4,167.2A16.1,16.1,0,0,0,56,180v36a16,16,0,0,0,16,16H184a16,16,0,0,0,16-16V180.4a16.1,16.1,0,0,0-6.4-12.8L141.3,128,164,110.8ZM72,40H184V75.6L178.2,80H77.3L72,76ZM184,180.4V216H72V180l48-36v24a8,8,0,0,0,16,0V144.1Z" />
    </Svg>
  );
}

export default HourglassMedium;
