/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function RewindCircle(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M24,128A104,104,0,1,0,128,24,104.2,104.2,0,0,0,24,128Zm44,0a8.2,8.2,0,0,1,3.4-6.6l40-28a8,8,0,0,1,8.3-.5A8.1,8.1,0,0,1,124,100v28a8.2,8.2,0,0,1,3.4-6.6l40-28a8,8,0,0,1,8.3-.5A8.1,8.1,0,0,1,180,100v56a8,8,0,0,1-8,8,8.6,8.6,0,0,1-4.6-1.4l-40-28A8.2,8.2,0,0,1,124,128v28a8,8,0,0,1-8,8,8.6,8.6,0,0,1-4.6-1.4l-40-28A8.2,8.2,0,0,1,68,128Z" />
    </Svg>
  );
}

export default RewindCircle;
