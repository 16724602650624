/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function CalendarCheck(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM169.5,133.8l-46.7,44a7.7,7.7,0,0,1-5.5,2.2,7.9,7.9,0,0,1-5.5-2.2l-25.3-24a8,8,0,1,1,11-11.6L117.3,161l41.2-38.8a7.9,7.9,0,0,1,11.3.3A8,8,0,0,1,169.5,133.8ZM208,80H48V48H72v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24Z" />
    </Svg>
  );
}

export default CalendarCheck;
