/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Ellipse, Line } from 'react-native-svg';

import { IconProps } from '../lib';

function MediumLogo(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Ellipse cx={72} cy={128} rx={56} ry={60} opacity={0.2} />
      <Ellipse cx={184} cy={128} rx={24} ry={56} opacity={0.2} />
      <Ellipse
        cx={72}
        cy={128}
        rx={56}
        ry={60}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Ellipse
        cx={184}
        cy={128}
        rx={24}
        ry={56}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={240}
        y1={72}
        x2={240}
        y2={184}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default MediumLogo;
