/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function ArrowSquareUp(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M224,208V48a16,16,0,0,0-16-16H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208A16,16,0,0,0,224,208Zm-56.4-80.4a8,8,0,0,1-11.3,0L136,107.3V168a8,8,0,0,1-16,0V107.3L99.7,127.6a8,8,0,0,1-11.3-11.3l33.9-33.9a8.7,8.7,0,0,1,2.6-1.8A8.5,8.5,0,0,1,128,80a7.7,7.7,0,0,1,3,.6,8,8,0,0,1,2.7,1.8l33.9,33.9A8,8,0,0,1,167.6,127.6Z" />
    </Svg>
  );
}

export default ArrowSquareUp;
