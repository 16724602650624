/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function GitDiff(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M232,188a36,36,0,1,1-44-35.1v-33a40,40,0,0,0-11.7-28.3L152,67.3V88a8,8,0,0,1-16,0V48a8,8,0,0,1,8-8h40a8,8,0,0,1,0,16H163.3l24.3,24.3A55.5,55.5,0,0,1,204,119.9v33A36.1,36.1,0,0,1,232,188ZM112,160a8,8,0,0,0-8,8v20.7L79.7,164.4A40,40,0,0,1,68,136.1v-33a36,36,0,1,0-16,0v33a55.5,55.5,0,0,0,16.4,39.6L92.7,200H72a8,8,0,0,0,0,16h40a8,8,0,0,0,8-8V168A8,8,0,0,0,112,160Z" />
    </Svg>
  );
}

export default GitDiff;
