/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function TextHSix(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M152,56V176a8,8,0,0,1-16,0V124H48v52a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0v52h88V56a8,8,0,0,1,16,0ZM252,168a36,36,0,1,1-67.4-17.5l.3-.6,32.2-54a8,8,0,0,1,13.8,8.2L214.2,132H216A36,36,0,0,1,252,168Zm-16,0a20,20,0,1,0-20,20A20.1,20.1,0,0,0,236,168Z" />
    </Svg>
  );
}

export default TextHSix;
