/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Ellipse, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function NumberSquareZero(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Ellipse cx={128} cy={128} rx={24} ry={40} />
      <Path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM128,184c-27.6,0-40-28.1-40-56s12.4-56,40-56,40,28.1,40,56S155.6,184,128,184Z" />
    </Svg>
  );
}

export default NumberSquareZero;
