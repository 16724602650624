/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function FilmStrip(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM184,56h32V72H184Zm-48,0h32V72H136ZM88,56h32V72H88ZM72,200H40V184H72ZM72,72H40V56H72ZM88,200V185.3h32V200Zm48,0V185.3h32V200Zm80,0H184V185.3h9.2V184H216v16Z" />
    </Svg>
  );
}

export default FilmStrip;
