/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function InfinityIcon(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M248,128a56,56,0,0,1-95.6,39.6l-.3-.4-60-67.6A39.5,39.5,0,0,0,64,88a40,40,0,1,0,0,80,39.5,39.5,0,0,0,28.1-11.6l8.5-9.6a8,8,0,0,1,12,10.6l-8.7,9.8-.3.4a56,56,0,0,1-79.2,0A56,56,0,0,1,64,72a55.5,55.5,0,0,1,39.6,16.4l.3.4,60,67.6A39.5,39.5,0,0,0,192,168a40,40,0,1,0,0-80,39.5,39.5,0,0,0-28.1,11.6l-8.5,9.6a8,8,0,0,1-12-10.6l8.7-9.8.3-.4A56,56,0,0,1,248,128Z" />
    </Svg>
  );
}

export default InfinityIcon;
