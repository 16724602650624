/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function TextHOne(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M152,56V176a8,8,0,0,1-16,0V124H48v52a8,8,0,0,1-16,0V56a8,8,0,0,1,16,0v52h88V56a8,8,0,0,1,16,0Zm71.8,44.9a8.3,8.3,0,0,0-8.2.4l-24,16a8,8,0,0,0,8.8,13.4l11.6-7.8V200a8,8,0,0,0,16,0V108A8,8,0,0,0,223.8,100.9Z" />
    </Svg>
  );
}

export default TextHOne;
