/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function PencilSimpleLine(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M216,208H115.3l82.4-82.3h0L224,99.3a15.9,15.9,0,0,0,0-22.6L179.3,32a16.1,16.1,0,0,0-22.6,0L36.7,152A15.9,15.9,0,0,0,32,163.3V208a16,16,0,0,0,16,16H216a8,8,0,0,0,0-16ZM48,163.3l96-96L188.7,112l-96,96H48Z" />
    </Svg>
  );
}

export default PencilSimpleLine;
