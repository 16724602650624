/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function ProhibitInset(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm22.6,137.9L94.1,105.4a8,8,0,0,1,11.3-11.3l56.5,56.5a8,8,0,0,1-11.3,11.3Z" />
    </Svg>
  );
}

export default ProhibitInset;
