/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function ArrowCircleUpRight(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M201.5,54.5a104,104,0,1,0,0,147A103.9,103.9,0,0,0,201.5,54.5ZM164,148a8,8,0,0,1-16,0V119.3l-42.3,42.4A8.5,8.5,0,0,1,100,164a8.3,8.3,0,0,1-5.7-2.3,8.1,8.1,0,0,1,0-11.4L136.7,108H108a8,8,0,0,1,0-16h48a7.7,7.7,0,0,1,3,.6,8.1,8.1,0,0,1,4.4,4.3,8.5,8.5,0,0,1,.6,3.1Z" />
    </Svg>
  );
}

export default ArrowCircleUpRight;
