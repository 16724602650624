/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function ChatCenteredDots(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M216,40H40A16,16,0,0,0,24,56V184a16,16,0,0,0,16,16H99.5l14.8,24.7a16,16,0,0,0,27.4,0L156.5,200H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40ZM80,132a12,12,0,1,1,12-12A12,12,0,0,1,80,132Zm48,0a12,12,0,1,1,12-12A12,12,0,0,1,128,132Zm48,0a12,12,0,1,1,12-12A12,12,0,0,1,176,132Z" />
    </Svg>
  );
}

export default ChatCenteredDots;
