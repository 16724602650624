/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function FilePng(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M60,160H44a8,8,0,0,0-8,8v48a8,8,0,0,0,16,0v-8h8a24,24,0,0,0,0-48Zm0,32H52V176h8a8,8,0,0,1,0,16Zm92-24v48a8,8,0,0,1-5.5,7.6,7.3,7.3,0,0,1-2.5.4,7.8,7.8,0,0,1-6.4-3.2L116,192v24a8,8,0,0,1-16,0V168a8,8,0,0,1,5.5-7.6,7.9,7.9,0,0,1,8.9,2.8L136,192V168a8,8,0,0,1,16,0Zm72,37.8a7.5,7.5,0,0,1-1.3,4.3A29.4,29.4,0,0,1,198,224c-16.5,0-30-14.4-30-32s13.5-32,30-32a28.4,28.4,0,0,1,16.6,5.4,8,8,0,0,1-9.2,13A12.6,12.6,0,0,0,198,176c-7.7,0-14,7.2-14,16s6.3,16,14,16a13.3,13.3,0,0,0,10-4.8V202h-4a8,8,0,0,1,0-16h12a8,8,0,0,1,8,8ZM48,136H208a8,8,0,0,0,8-8V88a8.1,8.1,0,0,0-2.3-5.7l-56-56A8.1,8.1,0,0,0,152,24H56A16,16,0,0,0,40,40v88A8,8,0,0,0,48,136ZM152,44l44,44H152Z" />
    </Svg>
  );
}

export default FilePng;
