/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Screencast(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M32,184a8,8,0,0,0,0,16,8,8,0,0,1,8,8,8,8,0,0,0,16,0A24.1,24.1,0,0,0,32,184Z" />
      <Path d="M32,152a8,8,0,0,0,0,16,40,40,0,0,1,40,40,8,8,0,0,0,16,0A56,56,0,0,0,32,152Z" />
      <Path d="M32,120a8,8,0,0,0,0,16,72.1,72.1,0,0,1,72,72,8,8,0,0,0,16,0A88.1,88.1,0,0,0,32,120Z" />
      <Path d="M216,40H40A16,16,0,0,0,24,56V96a8,8,0,0,0,8,8A104.2,104.2,0,0,1,136,208a8,8,0,0,0,8,8h72a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Z" />
    </Svg>
  );
}

export default Screencast;
