/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function ShieldChevron(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M208,40H48A16,16,0,0,0,32,56v58.7c0,89.4,75.8,119.1,91,124.1a14.3,14.3,0,0,0,10,0c15.2-5,91-34.7,91-124.1V56A16,16,0,0,0,208,40ZM48,56H208v58.7a115.1,115.1,0,0,1-8.8,45.4l-66.6-46.7a8.1,8.1,0,0,0-9.2,0L56.8,160.1A115.1,115.1,0,0,1,48,114.7Z" />
    </Svg>
  );
}

export default ShieldChevron;
