/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Horse(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M202.1,55A103.2,103.2,0,0,0,128,24h-8a8,8,0,0,0-8,8V59.5L11.8,121.2a8,8,0,0,0-3.6,5.2,7.8,7.8,0,0,0,1.3,6.3l13.8,19.1a31.6,31.6,0,0,0,31.3,12.8c13.7-2.3,38.6-4.2,61.6,6.2L90,207.4a91.1,91.1,0,0,1-20.7-13.8,8,8,0,0,0-11.3.6,8,8,0,0,0,.7,11.3A103.5,103.5,0,0,0,128,232h2.1a104,104,0,0,0,72-177ZM124,112a12,12,0,1,1,12-12A12,12,0,0,1,124,112Z" />
    </Svg>
  );
}

export default Horse;
