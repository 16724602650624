/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function Drop(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M174,47.8A259.4,259.4,0,0,0,132.6,9.4a8.1,8.1,0,0,0-9.2,0A259.4,259.4,0,0,0,82,47.8C54.5,79.3,40,112.6,40,144a88,88,0,0,0,176,0C216,112.6,201.5,79.3,174,47.8Zm9.2,105.6a55.8,55.8,0,0,1-45.8,45.7h-1.3a8,8,0,0,1-1.3-15.9,39.7,39.7,0,0,0,32.6-32.6,8,8,0,0,1,9.3-6.5A7.9,7.9,0,0,1,183.2,153.4Z" />
    </Svg>
  );
}

export default Drop;
