/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path, Line } from 'react-native-svg';

import { IconProps } from '../lib';

function Campfire(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path
        d="M128,32S76,64,76,108a52,52,0,0,0,52,52,23.9,23.9,0,0,1-24-24c0-24,24-40,24-40s24,16,24,40a23.9,23.9,0,0,1-24,24,52,52,0,0,0,52-52C180,64,128,32,128,32Z"
        opacity={0.2}
      />
      <Path
        d="M180,108a52,52,0,0,1-104,0c0-44,52-76,52-76S180,64,180,108Z"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={40}
        y1={168}
        x2={216}
        y2={224}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Line
        x1={216}
        y1={168}
        x2={40}
        y2={224}
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
      <Path
        d="M152,136a24,24,0,0,1-48,0c0-24,24-40,24-40S152,112,152,136Z"
        fill="none"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={16}
      />
    </Svg>
  );
}

export default Campfire;
