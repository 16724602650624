/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Circle, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function UserRectangle(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Circle cx={128} cy={120} r={44} fill={props.color} />
      <Path d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,160H201.3a80.2,80.2,0,0,0-32-36.5,59.9,59.9,0,0,1-82.6,0,80.2,80.2,0,0,0-32,36.5H40V56H216V200Z" />
    </Svg>
  );
}

export default UserRectangle;
