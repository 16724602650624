/* GENERATED FILE */
import * as React from 'react';
import Svg, { Rect, Path } from 'react-native-svg';

import { IconProps } from '../lib';

function CursorText(props: IconProps) {
  return (
    <Svg
      viewBox="0 0 256 256"
      width={props.size}
      height={props.size}
      fill={props.color}
      {...props}
    >
      <Rect width={256} height={256} fill="none" />
      <Path d="M184,208a8,8,0,0,1-8,8H160a40,40,0,0,1-32-16,40,40,0,0,1-32,16H80a8,8,0,0,1,0-16H96a24.1,24.1,0,0,0,24-24V136H104a8,8,0,0,1,0-16h16V80A24.1,24.1,0,0,0,96,56H80a8,8,0,0,1,0-16H96a40,40,0,0,1,32,16,40,40,0,0,1,32-16h16a8,8,0,0,1,0,16H160a24.1,24.1,0,0,0-24,24v40h16a8,8,0,0,1,0,16H136v40a24.1,24.1,0,0,0,24,24h16A8,8,0,0,1,184,208Z" />
    </Svg>
  );
}

export default CursorText;
